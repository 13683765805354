import React, { useEffect, useState } from 'react';
import { Breadcrumb, Alert, Card } from 'react-bootstrap';
import { useHistory } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import {
  getAllTradeAccounts,
  getAllTransactionHistory,
  getAllUnitDetails,
  getTradeAccount
} from '../../services/carbonManagementService';
import Loader from '../../components/Loader';
import Editicon from '../../assets/images/icons/edit.svg';
import { recordsPerPage, tradeAccountETSTypes, tradeAccountUsageTypes } from '../../util/constants';
import TableNoData from '../../components/TableNoData';
import { AppContext } from '../../app/App';
import { formatDate } from '../../common/formatter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

const columns = [
  {
    dataField: 'transactionId',
    text: 'Transaction ID',
    sort: true
  },
  {
    dataField: 'transactionDate',
    text: 'Transaction Date',
    sort: true,
    formatter: (cell, row) => formatDate(cell)
  },
  {
    dataField: 'transferedFromName',
    text: 'Transferred From'
  },
  {
    dataField: 'transferedToName',
    text: 'Transferred To'
  },
  {
    dataField: 'transactionType',
    text: 'Transaction Type'
  },
  {
    dataField: 'unitsReceived',
    text: 'Units Received'
  },
  {
    dataField: 'unitsTransferred',
    text: 'Units Transferred'
  },
  {
    dataField: 'status',
    text: 'Status'
  }
];

const unitColumns = [
  {
    dataField: 'cpName',
    text: 'CP',
    sort: true
  },
  {
    dataField: 'unitType',
    text: 'Unit Type',
    sort: true
  },
  {
    dataField: 'unitBalance',
    text: 'Unit Balance'
  },
  {
    dataField: 'availableBalance',
    text: 'Available Balance'
  }
];

const TradeAccountDetails = (props) => {
  const context = React.useContext(AppContext);
  let history = useHistory();
  const { instance } = useMsal();
  const tradeAccountId =
    props.location.state && props.location.state.tradeAccountId ? props.location.state.tradeAccountId : null;
  const [allTransactionHistory, setAllTransactionHistory] = useState([]);
  const [allUnitDetails, setAllUnitDetails] = useState([]);
  const [getUnitDetailsApiRequestInProgress, setGetUnitDetailApiRequestInProgress] = useState(false);
  const [getTransactionHistoryApiRequestInProgress, setGetTransactionHistoryApiRequestInProgress] = useState(true);
  const [getTradeAccountApiRequestInProgress, setGetTradeAccountApiRequestInProgress] = useState(true);
  const [tradeAccount, setTradeAccount] = useState(null);
  const [notification, setNotificationMessage] = useState(null);
  const tradeAccountName =
    props.location.state && props.location.state.tradeAccountName ? props.location.state.tradeAccountName : null;
  const [historyTableLoading, setHistoryTableLoading] = useState(false);
  const [unitDetailstableLoading, setUnitDetailstableLoading] = useState(false);
  const [totalHistoryCount, setTotalHistoryCount] = useState(0);
  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [totalUnitDetailsCount, setTotalUnitDetailsCount] = useState(0);
  const [currentUnitDetailsPage, setCurrentUnitDetailsPage] = useState(1);

  const getTradeAccountDetails = async () => {
    setGetTradeAccountApiRequestInProgress(true);
    try {
      const response = await getTradeAccount(tradeAccountId, instance);
      if (response) {
        setTradeAccount(response);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetTradeAccountApiRequestInProgress(false);
    }
  };

  const getUnitDetails = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setUnitDetailstableLoading(true);
    const data = {
      pageNumber,
      searchKeyWord,
      pageSize: recordsPerPage,
      sortBy,
      isAscSort
    };
    try {
      const response = await getAllUnitDetails(tradeAccountId, context.msalInstance, data);
      if (response) {
        setAllUnitDetails(response.paginatedList);
        setTotalUnitDetailsCount(response.totalCount);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUnitDetailstableLoading(false);
      setGetUnitDetailApiRequestInProgress(false);
    }
  };

  const getTransactionHistory = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setHistoryTableLoading(true);
    const data = {
      pageNumber,
      searchKeyWord,
      pageSize: recordsPerPage,
      sortBy,
      isAscSort
    };
    try {
      const response = await getAllTransactionHistory(tradeAccountId, data, context.msalInstance);
      if (response) {
        const accounts = await getTradeAccounts();
        const transactionData = response.paginatedList;
        setTotalHistoryCount(response.totalCount);
        transactionData.forEach((item, index) => {
          accounts.forEach((account) => {
            if (item.transferedFrom === account.accountNumber) {
              transactionData[index] = {
                ...transactionData[index],
                transferedFromName: account.name
              };
            }
            if (item.transferedTo === account.accountNumber) {
              transactionData[index] = {
                ...transactionData[index],
                transferedToName: account.name
              };
            }
          });
        });
        setAllTransactionHistory(transactionData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setHistoryTableLoading(false);
      setGetTransactionHistoryApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const getTradeAccounts = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    try {
      const data = {
        pageNumber,
        searchKeyWord,
        sortBy,
        isAscSort
      };
      const response = await getAllTradeAccounts(data, context.msalInstance);
      if (response && response.paginatedList) {
        return response.paginatedList;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUnitDetailsTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentUnitDetailsPage(pageNumber);
    getUnitDetails(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };

  const onHistoryTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentHistoryPage(pageNumber);
    getTransactionHistory(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };

  const options = {
    sizePerPage: recordsPerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true
  };

  useEffect(() => {
    getTradeAccountDetails();
    getTransactionHistory();
    getUnitDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/carbon-management">Carbon</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Trade Account Details</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
      {getTradeAccountApiRequestInProgress ? (
        <Loader />
      ) : (
        <div>
          {tradeAccount && (
            <section>
              <Card>
                <Card.Body>
                  <div className="trade-account-details">
                    <p>
                      ETS Type<b>{tradeAccountETSTypes[tradeAccount.etsType] || '-'}</b>
                    </p>
                    <p>
                      Trade Account Name
                      <b>{tradeAccount.name || '-'}</b>
                    </p>
                    <p>
                      Kyoto Account Number
                      <b>{tradeAccount.kyotoAccountNumber || '-'}</b>
                    </p>
                    <p>
                      Account Number
                      <b>{tradeAccount.accountNumber || '-'}</b>
                    </p>
                    <p>
                      Usage Type
                      <b>{tradeAccountUsageTypes[tradeAccount.usageType] || '-'}</b>
                    </p>
                  </div>
                  <div className="trade-account-details--btns">
                    <NavLink
                      to={{
                        pathname: '/carbon-management/update-trade-account',
                        state: { tradeAccountId, tradeAccountName }
                      }}
                      className="btn btn-primary"
                    >
                      <img src={Editicon} alt="edit icon" /> Edit
                    </NavLink>
                  </div>
                </Card.Body>
              </Card>
            </section>
          )}

          <section>
            <div>
              <div className="page-header">
                <div className="breadcrumb-wrapper">
                  <Breadcrumb>
                    <Breadcrumb.Item>Unit Details</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
              {getUnitDetailsApiRequestInProgress ? (
                <Loader />
              ) : (
                <ToolkitProvider
                  keyField="id"
                  data={allUnitDetails.map((item, index) => {
                    item.id = index;
                    return item;
                  })}
                  columns={unitColumns}
                >
                  {(props) => (
                    <div className="customer-orderhistory-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        classes="custom-tbl"
                        remote
                        hover
                        wrapperClasses="table-in-card"
                        loading={unitDetailstableLoading}
                        pagination={
                          totalUnitDetailsCount > recordsPerPage
                            ? paginationFactory({
                                ...options,
                                page: currentUnitDetailsPage,
                                totalSize: totalUnitDetailsCount
                              })
                            : null
                        }
                        onTableChange={onUnitDetailsTableChange}
                        noDataIndication={() => <TableNoData mainMessage="No data available" />}
                        overlay={overlayFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </div>
            <div className="transaction-total">
              {' '}
              <p>
                Total <b>{allUnitDetails && allUnitDetails.length > 0 ? allUnitDetails[0].total : 0}</b>
              </p>
            </div>
          </section>
          <section>
            <div>
              {getTransactionHistoryApiRequestInProgress ? (
                <Loader />
              ) : (
                <ToolkitProvider
                  keyField="id"
                  data={allTransactionHistory.map((item, index) => {
                    item.id = index;
                    return item;
                  })}
                  columns={columns}
                >
                  {(props) => (
                    <div className="customer-orderhistory-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        classes="custom-tbl"
                        remote
                        hover
                        wrapperClasses="table-in-card"
                        loading={historyTableLoading}
                        pagination={
                          totalHistoryCount > recordsPerPage
                            ? paginationFactory({
                                ...options,
                                page: currentHistoryPage,
                                totalSize: totalHistoryCount
                              })
                            : null
                        }
                        onTableChange={onHistoryTableChange}
                        noDataIndication={() => <TableNoData mainMessage="No data available" />}
                        overlay={overlayFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default TradeAccountDetails;
