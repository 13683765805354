import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumb, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { getOrder } from '../../services/orderService';
import OrderDetails from './OrderDetails';
import Loader from '../../components/Loader';
import { useDispatch } from 'react-redux';
import { clearNotification } from '../../app/actions';

const ViewOrder = () => {
  const location = useLocation();
  const { instance } = useMsal();
  const history = useHistory();
  const dispatch = useDispatch();

  const orderId = location.state && location.state.orderId ? location.state.orderId : null;

  const [receiverName, setReceiverName] = useState(
    location.state && location.state.receiverName ? location.state.receiverName : null
  );
  const [getOrderApiRequestInProgress, setGetOrderApiRequestInProgress] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const [readyToClaim, setReadyToClaim] = useState(false);
  const [carbonAttachment, setCarbonAttachment] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState();
  const [sentForInvoicing, setSentForInvoicing] = useState();
  const { orderIdParam, orderReleaseIdParam } = useParams();
  const [notification, setNotificationMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);

  const getOrderDetails = async (orderId) => {
    setGetOrderApiRequestInProgress(true);
    try {
      const response = await getOrder(orderId, instance);
      if (response) {
        setReceiverName(response.receiver.name);
        response.orderRelease.forEach((item) => {
          item.emissionPerItem = response.product.emissionPerItem;
          item.emissionPerItemUnit = response.product.emissionPerItemUnit;

          if (item.id === orderReleaseIdParam) {
            setSelectedRelease(item.batch);
          }
        });
        setOrderDetails(response);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetOrderApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl, modal) => {
    setModalOpen(modal);
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        dispatch(clearNotification());
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 3000 : 5000
    );
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId);
    }
    if (orderIdParam && orderReleaseIdParam) {
      getOrderDetails(orderIdParam);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId);
    }
    // eslint-disable-next-line
  }, [carbonAttachment, selectedRelease, sentForInvoicing]);

  return (
    <section>
      <div>
        <div className="page-header">
          <div className="breadcrumb-wrapper">
            {orderDetails.orderNo ? (
              <Breadcrumb>
                <Breadcrumb.Item href="/orders">Orders</Breadcrumb.Item>
                <Breadcrumb.Item active>{orderDetails.orderNo}</Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              ''
            )}
          </div>
          <NavLink
            to={{
              pathname: '/orders/edit-order',
              state: { orderId: orderId, orderNo: orderDetails.orderNo, receiverName: receiverName, status: orderDetails.status }
            }}
            className="btn btn-primary"
          >
            {/* <img src={AddOrder} alt="add user icon" /> */}
            {orderDetails.status === 4 ? "View Order Details" : "Edit Order Details"}
          </NavLink>
        </div>
        {!modalOpen && notification && notification.message && (
          <Alert variant={notification.type}>{notification.message}</Alert>
        )}
        <Row>
          <Col sm={12}>
            {getOrderApiRequestInProgress ? (
              <Loader />
            ) : (
              <Tabs defaultActiveKey={selectedRelease}>
                {orderDetails.orderRelease?.map((release, index) => {
                  return (
                    <Tab key={index} title={release.batch} eventKey={release.batch}>
                      <Card>
                        <Card.Body>
                          <OrderDetails
                            id={orderDetails.id}
                            createdDate={orderDetails.createdOn}
                            orderRelease={orderDetails.orderRelease[index]}
                            product={orderDetails.product}
                            customer={orderDetails.receiver}
                            readyToClaim={readyToClaim}
                            setReadyToClaim={setReadyToClaim}
                            carbonAttachment={setCarbonAttachment}
                            selected={setSelectedRelease}
                            sentForInvoicing={setSentForInvoicing}
                            orderNo={orderDetails.orderNo}
                            setNotifications={setNotifications}
                            notification={notification}
                          />
                        </Card.Body>
                      </Card>
                    </Tab>
                  );
                })}
              </Tabs>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ViewOrder;
