import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink, useHistory } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { CSVReader } from 'react-papaparse';
import BootstrapTable from 'react-bootstrap-table-next';
import TableNoData from '../../components/TableNoData';
import { verifyPermissions } from '../../common/permissionVerifier';
import { uploadCSVRecords } from '../../services/carbonManagementService';
import { formatDate } from '../../common/formatter';
import fail from '../../assets/images/icons/fail.svg';
import success from '../../assets/images/icons/success.svg';
import info from '../../assets/images/icons/info.svg';

const columns = [
  {
    dataField: 'transaction_Code',
    text: 'Transaction ID',
    sort: true
  },
  {
    dataField: 'the_Date',
    text: 'Transaction Date',
    sort: true,
    formatter: (cell, row) => formatDate(cell)
  },
  {
    dataField: 'account_Number',
    text: 'Transferred From'
  },
  {
    dataField: 'account_Number2',
    text: 'Transferred To'
  },
  {
    dataField: 'workflow_Type_Name',
    text: 'Transaction Type'
  },
  {
    dataField: 'number_Of_Units',
    text: 'Units Transferred'
  },
  {
    dataField: 'unit_Type',
    text: 'Unit Type'
  },
  {
    dataField: 'transaction_Status',
    text: 'Status'
  },
  {
    dataField: 'uploadStatus',
    text: 'Upload Status',
    hidden: true,
    formatter: (cell, row) => (
      <div>
        {row.uploadStatus ? (
          <div className="status-sec">
            <img className="information-icon" src={info} alt="info icon" />
            <div className="status-msg">
              <p>Success</p>
              <img className="status-icon" src={success} alt="status icon" />
            </div>
          </div>
        ) : (
          <div>
            {row.statusMessage ? (
              <span className="d-inline-block">
                <div className="status-sec">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip">{row.statusMessage}</Tooltip>}
                  >
                    <img style={{ cursor: 'pointer' }} className="information-icon" src={info} alt="info icon" />
                  </OverlayTrigger>
                  <div className="status-msg">
                    <p>Failed</p>
                    <img className="status-icon" src={fail} alt="status icon" />
                  </div>
                </div>
              </span>
            ) : (
              '-'
            )}
          </div>
        )}
      </div>
    )
  }
];

const TransactionsUploader = (props) => {
  const history = useHistory();
  const { instance } = useMsal();
  const [uploadedData, setUploadedData] = useState([]);
  const [uploadCSVApiRequestInProgress, setUploadCSVApiRequestInProgress] = useState(false);
  const [notification, setNotificationMessage] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  let rowClasses = (row) => (!row.uploadStatus ? 'alert-row' : 'success-row');

  const handleOnDrop = (data) => {
    const field = columns.findIndex((item) => item.dataField === 'uploadStatus');
    if (field) {
      columns[field].hidden = true;
    }
    formatCSV(data);
    setUploadComplete(false);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    const field = columns.findIndex((item) => item.dataField === 'uploadStatus');
    if (field) {
      columns[field].hidden = true;
    }
    setUploadedData([]);
  };

  const formatCSV = (data) => {
    let x = [];
    let newData = [];
    const headerRow = data.findIndex((data) => data.data[0] === 'Transaction_Code');
    const header = data[headerRow].data;
    data.forEach((item, index) => {
      if (item.data.length === header.length && index > headerRow) {
        header.forEach((head, index) => {
          if (head !== item.data[index]) {
            head.charAt(0).toLowerCase();
            head = head.charAt(0).toLowerCase() + head.slice(1);
            x = {
              ...x,
              [head]: head === 'the_Date' ? formatDate(item.data[index], 'YYYY-MM-DD') : item.data[index]
            };
          }
        });
        if (x.length !== 0) {
          newData.push(x);
        }
      }
    });
    setUploadedData(newData);
  };

  const uploadCSV = async () => {
    setUploadCSVApiRequestInProgress(true);
    try {
      uploadedData.forEach((value) => {
        value.the_Date = value.the_Date === '-' ? null : value.the_Date;
        value.number_Of_Units = value.number_Of_Units === '' ? null : value.number_Of_Units;
      });
      console.log(uploadedData);
      const response = await uploadCSVRecords(uploadedData, instance);
      if (response) {
        setUploadedData(response);
        setUploadComplete(true);
        const field = columns.findIndex((item) => item.dataField === 'uploadStatus');
        if (field) {
          columns[field].hidden = false;
        }
        setUploadCSVApiRequestInProgress(false);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setUploadCSVApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 3000 : 5000
    );
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="csv-upload-page">
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/carbon-management">Carbon</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Upload CSV</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div>
        {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}

        <CSVReader
          onDrop={handleOnDrop}
          onError={handleOnError}
          addRemoveButton
          onRemoveFile={handleOnRemoveFile}
          removeButtonColor="#333333"
          style={{
            dropFile: {
              width: 200,
              height: 120,
              backgroundColor: '#b3b3b3',
              padding: '4 0.4em',
              border: '2px solid #00e1be'
            },
            removeButton: {
              backgroundColor: '#333333'
            },
            progressBar: {
              backgroundColor: '#00e1be'
            }
          }}
        >
          <span>Drop CSV file here or click to upload.</span>
        </CSVReader>
      </div>
      <br />
      <Row>
        <Col lg={12}>
          <div className='csv-tbl-responsive'>
            <BootstrapTable
              classes="custom-tbl"
              columns={columns}
              keyField="Transaction_Code"
              data={uploadedData}
              noDataIndication={<TableNoData mainMessage="No data available" />}
              rowClasses={uploadComplete ? rowClasses : ''}
            />
          </div>
        </Col>
      </Row>
      <Button
        type="submit"
        onClick={() => uploadCSV()}
        className="btn btn-primary m-2"
        disabled={!verifyPermissions('write', instance) || uploadedData.length <= 0 || uploadComplete}
      >
        {uploadCSVApiRequestInProgress && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            variant="light"
            style={{ marginRight: '10px' }}
          />
        )}
        Upload Records
      </Button>
    </div>
  );
};

export default TransactionsUploader;
