import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import addUserIcon from '../../assets/images/icons/user-plus.svg';
import { NavLink } from 'react-router-dom';

import { getAllCustomers } from '../../services/partyService';
import { AppContext } from '../../app/App';
import NoData from '../../components/NoData';
import Loader from '../../components/Loader';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { recordsPerPage } from '../../util/constants';
import overlayFactory from 'react-bootstrap-table2-overlay';
import TableNoData from '../../components/TableNoData';
import SearchBar from '../../components/SearchBar';

const columns = [
  {
    dataField: 'name',
    headerClasses: 'custom-col',
    text: 'Comapany/Individual Name',
    sort: true
  },
  {
    dataField: 'primaryContactName',
    headerClasses: 'custom-col2',
    text: 'Key Contact',
    sort: true
  },
  {
    dataField: 'primaryContactTelephone',
    text: 'Mobile'
  }
];

const Customers = () => {
  const context = React.useContext(AppContext);
  let history = useHistory();
  const [allCustomers, setAllCustomers] = useState([]);
  const [getCustomersApiRequestInProgress, setGetCustomersApiRequestInProgress] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(false);

  const options = {
    sizePerPage: recordsPerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    totalSize: totalCount,
    page: currentPage
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push({
        pathname: `/customers/edit-customer`,
        state: { customerId: row.id, customerName: row.name, customerActiveStatus: row.onboardStatus }
      });
    }
  };

  const getCustomers = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    // setGetCustomersApiRequestInProgress(true);
    setTableLoading(true);

    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllCustomers(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllCustomers(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
          setTableLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetCustomersApiRequestInProgress(false);
    }
  };
  useEffect(() => {
    getCustomers(currentPage);
    // eslint-disable-next-line
  }, []);

  const onTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentPage(pageNumber);
    getCustomers(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };
  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;
  return (
    <section>
      <ToolkitProvider keyField="id" data={allCustomers} columns={columns} search={{ searchFormatted: true }}>
        {(props) => (
          <div>
            <div className="page-header">
              <div className="breadcrumb-wrapper">
                <Breadcrumb>
                  <Breadcrumb.Item>Customers</Breadcrumb.Item>
                </Breadcrumb>
                <SearchBar apiRequest={getCustomers} className="table-search" />
              </div>
              <NavLink to="/customers/create-customer" className="btn btn-primary">
                <img src={addUserIcon} alt="add user icon" />
                Create Customer
              </NavLink>
            </div>
            {getCustomersApiRequestInProgress ? (
              <Loader />
            ) : !isTableInitiallyEmpty ? (
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className='customer-tbl-responsive'>
                    <BootstrapTable
                      {...props.baseProps}
                      classes="custom-tbl"
                      remote
                      rowEvents={rowEvents}
                      hover
                      loading={tableLoading}
                      pagination={totalCount >= recordsPerPage ? paginationFactory(options) : null}
                      onTableChange={onTableChange}
                      noDataIndication={() => <NoDataIndication />}
                      overlay={overlayFactory()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <NoData
                  link="/customers/create-customer"
                  buttonText="Create New Customer"
                  mainMessage="You do not have Customers right now!"
                  secondaryMessage="Please click 'Create New Customer' to add a new Customer"
                  icon={addUserIcon}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </ToolkitProvider>
    </section>
  );
};

export default Customers;
