import React, { useEffect, useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createTransfer, getAllTradeAccounts } from '../../services/carbonManagementService';
import { Alert, Breadcrumb, Card, Button, Form, Spinner, Col, InputGroup } from 'react-bootstrap';
import { transferFormSchema } from './formValidation';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FormSkeleton } from '../../components/formSkeleton';
import { tradeAccountUsageTypes, transactionTypes } from '../../util/constants';
import { AppContext } from '../../app/App';
import Loader from '../../components/Loader';
import { FiCalendar } from 'react-icons/fi'; //import calendar icon from reat-icon
import DatePicker from 'react-datepicker';
import { fortmatTimStampWithTz } from '../../common/formatter';
import { useDispatch } from 'react-redux';
import { clearNotification } from '../../app/actions';

const TransferCarbon = (props) => {
  const context = React.useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const transferId = props.location.state && props.location.state.transferId ? props.location.state.transferId : null;
  const [notification, setNotificationMessage] = useState(null);
  const [allTradeAccounts, setAllTradeAccounts] = useState([]);
  const [getTradeAccountsApiRequestInProgress, setGetTradeAccountsApiRequestInProgress] = useState(true);
  const [fromAccount, setFromAccount] = useState();
  let datePickerRef = useRef('');

  const [getTransferApiRequestInProgress, setGetTransferApiRequestInProgress] = useState(false);
  const [saveTransfersApiRequestInProgress, setSaveTransfersApiRequestInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm({
    resolver: yupResolver(transferFormSchema),
    mode: 'all',
    defaultValues: {
      serials: [{ startSerial: '', endSerial: '' }]
    }
  });

  const resetForm = () => {
    reset();
  };

  const { fields, append, remove } = useFieldArray({
    name: "serials",
    control
  });

  const onSubmitHandler = (data) => {
    transferFormSchema
      .validate(data)
      .then(function (value) {
        createNewTransfer(value);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const createNewTransfer = async (transferData) => {
    transferData = {
      ...transferData,
      transactionDate: fortmatTimStampWithTz(new Date(transferData.transactionDate)),
      serials: validateSerialsArray(transferData.serials)
    };
    setSaveTransfersApiRequestInProgress(true);
    try {
      const resp = await createTransfer(location.pathname, dispatch, transferData, instance);
      if (resp) {
        await setNotifications('success', 'Carbon Transferred Successfully');
        resetForm();
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveTransfersApiRequestInProgress(false);
    }
  };

  /** Validates the serials array => returns an empty array if serial numbers are null */
  const validateSerialsArray = (serials) => {
    const returnMap = [];
    serials.map((serial) =>
      serial.endSerial && serial.startSerial
        ? returnMap.push({ startSerial: serial.startSerial.toString(), endSerial: serial.endSerial.toString() })
        : null
    );
    return returnMap;
  };

  const setFromAccountType = (selected) => {
    let account = allTradeAccounts.filter((account) => account.id.includes(selected));
    setFromAccount(account[0].usageType);
  };
  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        dispatch(clearNotification());
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const getTradeAccounts = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    try {
      const data = {
        pageNumber,
        searchKeyWord,
        sortBy,
        isAscSort
      };
      const response = await getAllTradeAccounts(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllTradeAccounts(response.paginatedList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetTradeAccountsApiRequestInProgress(false);
    }
  };

  const openDatepicker = () => datePickerRef.current.setOpen(true);

  useEffect(() => {
    getTradeAccounts();
    setGetTransferApiRequestInProgress(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/carbon-management">Carbon Management</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Transfer Carbon</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {getTradeAccountsApiRequestInProgress ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-lg-8">
            <Card>
              <Card.Body>
                {notification && notification.message && (
                  <Alert variant={notification.type}>{notification.message}</Alert>
                )}
                <Form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="none">
                  <div className="row">
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <div className="col-lg-6">
                        <Form.Label>From</Form.Label>
                        <Form.Control
                          as="select"
                          type="select"
                          {...register('fromId')}
                          isInvalid={errors.fromId}
                          onChange={(e) => setFromAccountType(e.target.value)}
                        >
                          <option value="">Select From</option>
                          {Object.keys(allTradeAccounts).map((account, index) => (
                            <option key={index} value={allTradeAccounts[account].id}>
                              {allTradeAccounts[account].name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.fromId?.message}</Form.Control.Feedback>
                      </div>
                    )}
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <div className="col-lg-6">
                        <Form.Label>To</Form.Label>

                        <Form.Control as="select" type="select" {...register('toId')} isInvalid={errors.toId}>
                          <option value="">Select To</option>
                          {Object.keys(allTradeAccounts).map(
                            (account, index) =>
                              ((fromAccount === 1 &&
                                tradeAccountUsageTypes[allTradeAccounts[account].usageType] ===
                                  tradeAccountUsageTypes[2]) ||
                                (fromAccount === 3 &&
                                  tradeAccountUsageTypes[allTradeAccounts[account].usageType] ===
                                    tradeAccountUsageTypes[1]) ||
                                (fromAccount === 2 &&
                                  tradeAccountUsageTypes[allTradeAccounts[account].usageType] ===
                                    tradeAccountUsageTypes[4])) && (
                                <option key={index} value={allTradeAccounts[account].id}>
                                  {allTradeAccounts[account].name}
                                </option>
                              )
                          )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.toId?.message}</Form.Control.Feedback>
                      </div>
                    )}
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <div className="col-lg-6">
                        <Form.Label>Transaction ID</Form.Label>
                        <Form.Control
                          {...register('transactionId')}
                          isInvalid={errors.transactionId}
                          placeholder="Please enter transaction ID"
                          autoComplete="none"
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.transactionId?.message}</Form.Control.Feedback>
                      </div>
                    )}
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <div className="col-lg-6">
                        <Form.Label>Transaction Date</Form.Label>
                        <Controller
                          name="transactionDate"
                          control={control}
                          {...register('transactionDate')}
                          render={({ field, fieldState: { invalid } }) => {
                            return (
                              <React.Fragment>
                                <InputGroup className="calendar-container">
                                  <div className="calender-icon" onClick={openDatepicker}>
                                    <FiCalendar />
                                  </div>
                                  <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date ? date : '')}
                                    className={`form-control ${invalid ? 'is-invalid' : ''}`}
                                    placeholderText="Please enter transaction date"
                                    todayButton="Today"
                                    isClearable={field.value}
                                    onBlur={field.onBlur}
                                    strictParsing={true}
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                    ref={datePickerRef}
                                  />
                                </InputGroup>

                                {invalid && <div className="error">{errors.transactionDate?.message}</div>}
                              </React.Fragment>
                            );
                          }}
                        />
                      </div>
                    )}
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <React.Fragment>
                        <div className="col-lg-6">
                          <Form.Label>Transaction Type</Form.Label>
                          <Form.Control as="select" type="select" {...register('type')} isInvalid={errors.type}>
                            <option value="">Select transaction type</option>
                            {Object.keys(transactionTypes).map((usageType, index) => (
                              <option key={index} value={usageType}>
                                {transactionTypes[usageType]}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
                        </div>
                        <div className="col-lg-6"></div>
                      </React.Fragment>
                    )}
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <div className="col-lg-6">
                        <Form.Label>Unit Type</Form.Label>
                        <Form.Control
                          {...register('unitType')}
                          isInvalid={errors.unitType}
                          placeholder="Please enter unit type"
                          autoComplete="none"
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.UnitType?.message}</Form.Control.Feedback>
                      </div>
                    )}
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <div className="col-lg-6">
                        <Form.Label>Units</Form.Label>
                        <Form.Control
                          type="number"
                          {...register('units')}
                          isInvalid={errors.units}
                          placeholder="Please enter units"
                          autoComplete="none"
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.units?.message}</Form.Control.Feedback>
                      </div>
                    )}
                    {getTransferApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <Col lg={12}>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="4"
                          {...register('notes')}
                          isInvalid={errors.notes}
                          maxLength={100}
                          autoComplete="none"
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.notes?.message}</Form.Control.Feedback>
                      </Col>
                    )}
                    {fields.map((field, index) => (
                      <span className='serial-s-e' key={field.id}>
                      {getTransferApiRequestInProgress ? (
                        <FormSkeleton size="6" />
                      ) : (
                        <div className="rel-field">
                          <Form.Label>Start Serial</Form.Label>
                          <Form.Control
                            {...register(`serials[${index}].startSerial`)}
                            name={`serials[${index}].startSerial`}
                            isInvalid={errors?.serials?.[index]?.startSerial}
                            placeholder="Please enter start serial"
                            autoComplete="none"
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">{errors?.serials?.[index]?.startSerial?.message}</Form.Control.Feedback>
                        </div>
                      )}
                      {getTransferApiRequestInProgress ? (
                        <FormSkeleton size="6" />
                      ) : (
                        <div className="rel-field">
                          <Form.Label>End Serial</Form.Label>
                          <Form.Control
                            {...register(`serials[${index}].endSerial`)}
                            name={`serials[${index}].endSerial`}
                            isInvalid={errors?.serials?.[index]?.endSerial}
                            placeholder="Please enter end serial"
                            autoComplete="none"
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">{errors?.serials?.[index]?.endSerial?.message}</Form.Control.Feedback>
                        </div>
                      )}
                        {fields.length > 1 && <div>
                          <button type="button" className="btn btn-danger button remove" onClick={() => remove(index)}>Remove</button> 
                        </div>}
                      </span>
                    ))}
                    <div>
                      <button className="btn button btn-outline-primary add" type="button" onClick={() => append({startSerial: '', endSerial: ''})}>Add</button>
                    </div>
                  </div>
                  <div className="form-buttons">
                    {transferId ? (
                      <NavLink to="/users" className="btn btn-light">
                        Cancel
                      </NavLink>
                    ) : (
                      <span onClick={resetForm} className="btn btn-light">
                        Clear
                      </span>
                    )}
                    <Button
                      type="submit"
                      className="btn btn-primary m-2"
                      disabled={getTransferApiRequestInProgress || saveTransfersApiRequestInProgress}
                    >
                      {saveTransfersApiRequestInProgress && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          variant="light"
                          style={{ marginRight: '10px' }}
                        />
                      )}
                      Transfer Carbon
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransferCarbon;
