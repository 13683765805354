import React from 'react';
import { Form } from 'react-bootstrap';

const SearchBar = ({ apiRequest, className }) => {
  const handleSearchChange = (value) => {
    apiRequest(0, value, null, null);
  };

  return (
    <div className='search-label'>
      <span className="sr-only"></span>
      <Form.Control
      className={className}
      placeholder="Search"
      onChange={(e) => {
        handleSearchChange(e.target.value);
      }}
      autoComplete="none"
      maxLength={100}
    ></Form.Control>
    </div>
    
  );
};

export default SearchBar;
