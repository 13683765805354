import * as yup from 'yup';

export const generateReportFormSchema = yup.object().shape({
  fromDate: yup
    .date()
    .required('From Date is a required field')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  toDate: yup
    .date()
    .required('To Date is a required field')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  reportType: yup
    .date()
    .required('Report Type is a required field')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
});
