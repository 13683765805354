import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Alert, Breadcrumb, Card, Col, Row, Tab, Tabs, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import CustomerForm from './CustomerForm';
import { deactivateCustomer, getCustomer, getOrderHistory, getTokenData } from '../../services/partyService';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import { OnboardStatus } from '../../util/enums';
import ConfirmAction from '../../components/ConfirmAction';
import { verifyPermissions } from '../../common/permissionVerifier';
import { formatDate, formatRoles } from '../../common/formatter';
import { approvalStatusArray, orderStatus } from '../../util/constants';
import UserAssignModal from './UserAssignModal';
import { recordsPerPage } from '../../util/constants';
import { assignUserstoCustomer, getAllUsers } from '../../services/userService';
import TableNoData from '../../components/TableNoData';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import SearchBar from '../../components/SearchBar';

const columns = [
  {
    dataField: 'orderNo',
    text: 'Order No',
    sort: true
  },
  {
    dataField: 'createdOn',
    text: 'Order Date',
    sort: true,
    formatter: (cell, row) => formatDate(cell)
  },
  {
    dataField: 'carbonUnits',
    text: 'Carbon Amount',
    sort: true
  },
  {
    dataField: 'status',
    text: 'Order Status',
    sort: true,
    formatter: (cell, row) => orderStatus[cell]
  }
];

const tokenColumns = [
  {
    dataField: 'orderNo',
    text: 'Order No',
    sort: true
  },
  {
    dataField: 'createdDate',
    text: 'Order Date',
    sort: true,
    formatter: (cell, row) => formatDate(cell)
  },
  {
    dataField: 'batch',
    text: 'Batch No',
    sort: true
  },
  {
    dataField: 'approvalStatus',
    text: 'Token Status',
    sort: true,
    formatter: (cell, row) =>
      approvalStatusArray[cell] ? approvalStatusArray[cell].replace(/([A-Z])/g, ' $1').trim() : '-'
  }
];

const usersColumns = [
  {
    dataField: 'firstName',
    text: 'Name',
    sort: true,
    formatter: (cell, row) => `${row.firstName || ''} ${row.lastName || ''}`
  },
  {
    dataField: 'roles',
    headerClasses: 'custom-col2',
    text: 'USER TYPE',
    sort: true,
    formatter: (cell, row) => formatRoles(cell) || '-'
  }
];

const EditCustomer = (props) => {
  const history = useHistory();
  const { instance } = useMsal();
  const [allOrders, setAllOrders] = useState([]);
  const [allTokens, setAllTokens] = useState([]);
  const [allUsers, setAllUsers] = useState();
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [notification, setNotificationMessage] = useState(null);
  const [getCustomerApiRequestInProgress, setGetCustomerApiRequestInProgress] = useState(false);
  const [getOrderHistoryApiRequestInProgress, setGetOrderHistoryApiRequestInProgress] = useState(false);
  const [changeCustomersStatusInProgress, setChangeCustomersStatusInProgress] = useState();
  const [getUsersApiRequestInProgress, setGetUsersApiRequestInProgress] = useState();
  const [getAssignedUsersApiRequestInProgress, setGetAssignedUsersApiRequestInProgress] = useState();
  const [getTokenDetailsApiRequestInProgress, setGetTokenDetailsApiRequestInProgress] = useState();
  const [assignUsersApiInprogess, setAssignUsersApiInprogess] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const location = useLocation();
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTokenCount, setTotalTokenCount] = useState(0);
  const [currentTokenPage, setCurrentTokenPage] = useState(1);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [currentOrdersPage, setCurrentOrdersPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState('customerDetails');
  const [userAssignModalKey, setUserAssignModalKey] = useState(new Date().toISOString());
  const [confirmActionModalKey, setConfirmActionModalKey] = useState(new Date().toISOString() + 1);

  const customerId = location.state && location.state.customerId ? location.state.customerId : null;
  const customerName = location.state && location.state.customerName ? location.state.customerName : null;

  const getOrders = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetOrderHistoryApiRequestInProgress(true);
    setTableLoading(true);
    const data = {
      pageNumber,
      pageSize: recordsPerPage,
      searchKeyWord,
      sortBy,
      isAscSort
    };
    try {
      const response = await getOrderHistory(customerId, data, instance);
      if (response) {
        setAllOrders(response.paginatedList);
        setTotalOrdersCount(response.totalCount);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetOrderHistoryApiRequestInProgress(false);
      setTableLoading(false);
    }
  };

  const getTokens = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetTokenDetailsApiRequestInProgress(true);
    setTableLoading(true);
    const data = {
      pageNumber,
      pageSize: recordsPerPage,
      searchKeyWord,
      sortBy,
      isAscSort
    };
    try {
      const response = await getTokenData(customerId, data, instance);
      if (response) {
        setAllTokens(response.paginatedList);
        setTotalTokenCount(response.totalCount);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetTokenDetailsApiRequestInProgress(false);
      setTableLoading(false);
    }
  };

  const changeCustomerStatus = async () => {
    setChangeCustomersStatusInProgress(true);
    try {
      const data = { customerId, status: isActive ? OnboardStatus.SUSPENDED : OnboardStatus.ONBOARDED };
      const resp = await deactivateCustomer(data, null, instance);
      if (resp) {
        handleConfirmActionModalClose();
        setSelectedTab('customerDetails');
        await setNotifications('success', `Customer Successfully ${isActive ? ' Deactivated' : ' Activated'}`);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setChangeCustomersStatusInProgress(false);
      getCustomerDetails(customerId);
    }
  };

  const assignUsers = async (users) => {
    setAssignUsersApiInprogess(true);
    try {
      const response = await assignUserstoCustomer(customerId, users, instance);
      if (response) {
        setShowAssignUserModal(false);
        setSelectedTab('assignUsers');
        setNotifications('success', 'Users Successfully Assigned');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setAssignUsersApiInprogess(false);
      getCustomerDetails(customerId);
    }
  };

  const getUsers = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetUsersApiRequestInProgress(true);
    try {
      const data = {
        pageNumber,
        pageSize: 0,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllUsers(data, instance);
      if (response && response.paginatedList) {
        setAllUsers(response.paginatedList.map((i) => ({ ...i, label: `${i.firstName} ${i.lastName}`, value: i.id })));
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetUsersApiRequestInProgress(false);
    }
  };

  const getAssignedUsers = async (pageNumber = 1, searchKeyWord = customerId, sortBy = null, isAscSort = null) => {
    setGetAssignedUsersApiRequestInProgress(true);
    setTableLoading(true);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllUsers(data, instance);
      if (response && response.paginatedList) {
        setAssignedUsers(response.paginatedList);
        setTotalCount(response.totalCount);
        setSelectedTab(null);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setTableLoading(false);
      setGetAssignedUsersApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });

    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const onTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentPage(pageNumber);
    getAssignedUsers(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };

  const onTokenTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentTokenPage(pageNumber);
    getTokens(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };

  const onOrderTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentOrdersPage(pageNumber);
    getOrders(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };

  useEffect(() => {
    getOrders();
    getTokens();
    getCustomerDetails(customerId);
    getUsers();
    getAssignedUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAssignedUsers();
    getUsers();
    // eslint-disable-next-line
  }, [selectedTab]);

  const getCustomerDetails = async (customerId) => {
    setGetCustomerApiRequestInProgress(true);
    try {
      const response = await getCustomer(customerId, instance);
      if (response) {
        setCustomer(response);
        setIsActive(response.onboardStatus === OnboardStatus.ONBOARDED);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetCustomerApiRequestInProgress(false);
    }
  };

  const handleConfirmActionModalClose = () => {
    setConfirmActionModalKey(new Date().toISOString());
    setShowConfirmActionModal(false);
  };

  const handleConfirmActionModalShow = () => setShowConfirmActionModal(true);

  const handleAssignUsersModalState = (state) => {
    setUserAssignModalKey(new Date().toISOString());
    setShowAssignUserModal(state);
  };

  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;

  const options = {
    sizePerPage: recordsPerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(row);
      history.push({
        pathname: `/orders/view-order`,
        state: { orderId: row.id }
      });
    }
  };

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item href="/customers">Customers</Breadcrumb.Item>
            <Breadcrumb.Item active>{customerName}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {!showAssignUserModal && notification && notification.message && (
          <Alert variant={notification.type}>{notification.message}</Alert>
        )}
        {!changeCustomersStatusInProgress &&
          !getCustomerApiRequestInProgress &&
          customer &&
          (customer.onboardStatus === OnboardStatus.SUSPENDED ||
            customer.onboardStatus === OnboardStatus.ONBOARDED) && (
            <Button
              className={isActive ? 'btn btn-danger m-2' : 'btn btn-primary m-2'}
              disabled={
                !verifyPermissions('write', instance) ||
                getCustomerApiRequestInProgress ||
                changeCustomersStatusInProgress
              }
              onClick={handleConfirmActionModalShow}
            >
              {`${isActive ? 'Deactivate' : 'Activate'} User`}
            </Button>
          )}
      </div>
      {!showConfirmActionModal && changeCustomersStatusInProgress ? (
        <Loader />
      ) : (
        <Row className="customer-account">
          <Col lg={8}>
            <Tabs defaultActiveKey={selectedTab}>
              <Tab title="Customer Details" eventKey="customerDetails">
                <Card>
                  <Card.Body>
                    <CustomerForm customerId={customerId} />
                  </Card.Body>
                </Card>
              </Tab>
              {/* -----------------------------
            Order History
            ----------------------------- */}
              <Tab title="Order History" eventKey="orderHistory">
                <Card>
                  <Card.Body>
                    <div>
                      <ToolkitProvider keyField="id" data={allOrders} key="id" columns={columns} search>
                        {(props) => (
                          <Row>
                            <Col lg={12}>
                              <SearchBar apiRequest={getOrders} className="table-search search-in-card" />
                            </Col>
                            <Col lg={12}>
                              {getOrderHistoryApiRequestInProgress ? (
                                <Loader />
                              ) : (
                                <div className="customer-orderhistory-responsive">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    classes="custom-tbl"
                                    remote
                                    hover
                                    wrapperClasses="table-in-card"
                                    loading={tableLoading}
                                    pagination={
                                      totalOrdersCount > recordsPerPage
                                        ? paginationFactory({
                                            ...options,
                                            page: currentOrdersPage,
                                            totalSize: totalOrdersCount
                                          })
                                        : null
                                    }
                                    onTableChange={onOrderTableChange}
                                    noDataIndication={() => <NoDataIndication />}
                                    overlay={overlayFactory()}
                                    rowEvents={rowEvents}
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      </ToolkitProvider>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              {/* -----------------------------
            Token Details
            ----------------------------- */}
              <Tab title="Tokens Details" eventKey="tokenDetails">
                <Card>
                  <Card.Body>
                    <div>
                      <ToolkitProvider keyField="id" data={allTokens} columns={tokenColumns} search>
                        {(props) => (
                          <Row>
                            <Col lg={12}>
                              <SearchBar apiRequest={getTokens} className="table-search search-in-card" />
                            </Col>
                            <Col lg={12}>
                              {getTokenDetailsApiRequestInProgress ? (
                                <Loader />
                              ) : (
                                <div className="order-tbl-responsive">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    classes="custom-tbl"
                                    remote
                                    hover
                                    wrapperClasses="table-in-card"
                                    loading={tableLoading}
                                    pagination={
                                      totalTokenCount > recordsPerPage
                                        ? paginationFactory({
                                            ...options,
                                            page: currentTokenPage,
                                            totalSize: totalTokenCount
                                          })
                                        : null
                                    }
                                    onTableChange={onTokenTableChange}
                                    noDataIndication={() => <NoDataIndication />}
                                    overlay={overlayFactory()}
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      </ToolkitProvider>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              {/* -----------------------------
            Assign Users
            ----------------------------- */}
              <Tab title="Users" eventKey="assignUsers">
                <Card>
                  <Card.Body>
                    <div>
                      <ToolkitProvider keyField="id" data={assignedUsers} columns={usersColumns}>
                        {(props) => (
                          <Row>
                            <Col lg={12}>
                              <div className="d-flex  btn-in-card">
                                <Button
                                  className="btn btn-primary m-2"
                                  disabled={
                                    !verifyPermissions('write', instance) ||
                                    getCustomerApiRequestInProgress ||
                                    changeCustomersStatusInProgress ||
                                    getUsersApiRequestInProgress ||
                                    assignUsersApiInprogess
                                  }
                                  onClick={() => handleAssignUsersModalState(true)}
                                >
                                  Assign Users
                                </Button>
                              </div>
                            </Col>
                            <Col lg={12}>
                              {getAssignedUsersApiRequestInProgress ? (
                                <Loader />
                              ) : (
                                <div className="order-tbl-responsive">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    classes="custom-tbl"
                                    remote
                                    hover
                                    wrapperClasses="table-in-card"
                                    loading={tableLoading}
                                    pagination={
                                      totalCount > recordsPerPage
                                        ? paginationFactory({ ...options, page: currentPage, totalSize: totalCount })
                                        : null
                                    }
                                    onTableChange={onTableChange}
                                    noDataIndication={() => <NoDataIndication />}
                                    overlay={overlayFactory()}
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      </ToolkitProvider>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
      <ConfirmAction
        key={confirmActionModalKey}
        show={showConfirmActionModal}
        title={isActive ? 'Deactivate Customer' : 'Activate Customer'}
        content={'Are you sure you want to ' + (isActive ? 'deactivate' : 'activate') + ' this customer?'}
        action={isActive ? 'Deactivate' : 'Activate'}
        handleClose={handleConfirmActionModalClose}
        onDeleteConfirm={changeCustomerStatus}
        isLoading={changeCustomersStatusInProgress}
        actionButtonVariant="danger"
        onActionConfirm={changeCustomerStatus}
      />

      <UserAssignModal
        key={userAssignModalKey}
        title={'Assign Users'}
        users={allUsers}
        show={showAssignUserModal}
        handleClose={() => handleAssignUsersModalState(false)}
        onAssignUsers={assignUsers}
        instance={instance}
        isLoading={assignUsersApiInprogess}
        action="Assign Users"
        notification={notification}
      />
    </div>
  );
};

export default EditCustomer;
