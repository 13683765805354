import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "../src/app/App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
// import "bootstrap/dist/css/bootstrap.min.css";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./util/authConfig";
import { appConfigs } from "./util/appConfigs";
import * as FullStory from '@fullstory/browser';
import 'bootstrap-daterangepicker/daterangepicker.css';

FullStory.init({ orgId: 'o-1AXZG0-na1', devMode: appConfigs.environment === 'development' });

Sentry.init({
  dsn: "https://b046a94dc4cf4022be0585d102385a85@sentry.itelalabs.net/6",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log(JSON.stringify(event));
  }
});

ReactDOM.render(
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App msalInstance={msalInstance}/>
      </MsalProvider>
    </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
