import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { authorizeXero, getIntegrations, integrateXero, removeIntegration } from '../../services/xeroService';
import { AppContext } from '../../app/App';
import Loader from '../../components/Loader';
import { Alert, Breadcrumb, Row, Col, Button, Card } from 'react-bootstrap';
import { formatTimeStamp } from '../../common/formatter';
import { useHistory } from 'react-router';
import { verifyPermissions } from '../../common/permissionVerifier';
import ConfirmAction from '../../components/ConfirmAction';
import NoData from '../../components/NoData';
import addUserIcon from '../../assets/images/icons/user-plus.svg';
import xeroRoundLogo from '../../assets/images/round-logo.svg';
import { useDispatch } from 'react-redux';
import { clearNotification } from '../../app/actions';

const XeroIntegration = (props) => {
  const search = useLocation().search;
  const history = useHistory();
  const dispatch = useDispatch();
  const code = new URLSearchParams(search).get('code');
  const context = React.useContext(AppContext);
  const [integrateXeroApiRequestInProgress, setIntegrateXeroApiRequestInProgress] = useState(false);
  const [getAllXeroIntegrationsApiRequestInProgress, setGetAllXeroIntegrationsApiRequestInProgress] = useState(false);
  const [removeXeroIntegrationApiRequestInProgress, setRemoveXeroIntegrationApiRequestInProgress] = useState(false);
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);

  const [notification, setNotificationMessage] = useState(null);
  const [integrations, setIntegrations] = useState([]);
  const [integrationId, setIntegrationId] = useState([]);

  const removeIntegrations = async (id) => {
    setRemoveXeroIntegrationApiRequestInProgress(true);
    try {
      const response = await removeIntegration(id, context.msalInstance);
      if (response) {
        setShowConfirmActionModal(false);
      }
    } catch (error) {
      console.log(error);
      setShowConfirmActionModal(false);
      setNotifications('danger', error.title, '/xero');
    } finally {
      setRemoveXeroIntegrationApiRequestInProgress(false);
      getAllXeroIntegrations();
    }
  };

  const getAllXeroIntegrations = async () => {
    setGetAllXeroIntegrationsApiRequestInProgress(true);
    try {
      const response = await getIntegrations(context.msalInstance);
      if (response) {
        setIntegrations(response);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetAllXeroIntegrationsApiRequestInProgress(false);
    }
  };

  const initiateXeroIntegration = async () => {
    authorizeXero();
  };

  const addXeroIntegration = async (code) => {
    setIntegrateXeroApiRequestInProgress(true);
    try {
      const data = {
        name: 'Xero Integration 1',
        type: 1,
        code: code
      };

      const response = await integrateXero(data, context.msalInstance);
      if (response) {
        dispatch(clearNotification());
        history.push('/xero');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title, '/xero');
    } finally {
      setIntegrateXeroApiRequestInProgress(false);
      getAllXeroIntegrations();
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };
  useEffect(() => {
    if (code) {
      addXeroIntegration(code);
    }
    getAllXeroIntegrations();
    //eslint-disable-next-line
  }, []);

  return (
    <section className="integration-page">
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item active>Xero Integration Details</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {integrations.length < 1 ? (
          <Button
            to="/orders/create-order"
            className="btn btn-primary"
            disabled={
              !verifyPermissions('integration', context.msalInstance) ||
              removeXeroIntegrationApiRequestInProgress ||
              integrateXeroApiRequestInProgress ||
              getAllXeroIntegrationsApiRequestInProgress
            }
            onClick={() => initiateXeroIntegration()}
          >
            Add Integration
          </Button>
        ) : (
          ''
        )}
      </div>
      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}

      <Row>
        {integrateXeroApiRequestInProgress || getAllXeroIntegrationsApiRequestInProgress ? (
          <Loader />
        ) : (
          <div>
            {integrations.length > 0 ? (
              integrations.map((item) => {
                return (
                  <Card classname="card-details">
                    <Card.Body>
                      <Row>
                        <Col lg={4}>
                          <img src={xeroRoundLogo} alt="xero round logo" className="logo" />
                        </Col>
                        <Col lg={8} className="card-descrip">
                          <Card.Title>{item.name}</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">{formatTimeStamp(item.expireAtUTC)}</Card.Subtitle>
                        </Col>
                        <div>
                          <Button
                            className="detail-btn"
                            variant="outline-danger"
                            onClick={() => {
                              setIntegrationId(item.id);
                              setShowConfirmActionModal(true);
                            }}
                            disabled={
                              !verifyPermissions('integration', context.msalInstance) ||
                              removeXeroIntegrationApiRequestInProgress ||
                              integrateXeroApiRequestInProgress ||
                              getAllXeroIntegrationsApiRequestInProgress
                            }
                            size="sm"
                          >
                            Remove
                          </Button>
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })
            ) : (
              <React.Fragment>
                <NoData
                  mainMessage="You do not have any Integrations right now!"
                  secondaryMessage="Please click 'Add Integration' to add a new Xero Integration"
                  icon={addUserIcon}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </Row>
      <ConfirmAction
        show={showConfirmActionModal}
        title="Confirm"
        content="Do you want to remove the integration?"
        action="Confirm"
        onActionConfirm={() => {
          removeIntegrations(integrationId);
        }}
        handleClose={() => setShowConfirmActionModal(false)}
        inprogress={removeXeroIntegrationApiRequestInProgress || getAllXeroIntegrationsApiRequestInProgress}
      />
    </section>
  );
};

export default XeroIntegration;
