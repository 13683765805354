import React from 'react';
import analytics from '../assets/images/icons/analytics.svg';
import users from '../assets/images/icons/user.svg';
import customers from '../assets/images/icons/customers.svg';
import orders from '../assets/images/icons/orders.svg';
import carbon from '../assets/images/icons/carbon.svg';
import integrationIcon from '../assets/images/icons/settings.svg';
import reports from '../assets/images/icons/report.svg';
import logo from '../assets/images/logo_white.svg';
import { NavLink } from 'react-router-dom';
import { verifyPermissions } from '../common/permissionVerifier';
import { AppContext } from '../app/App';


const SideNav = () => {
  const context = React.useContext(AppContext);

  return (
    <div className="sidenav">
      <img src={logo} alt="logo" className="logo" />
      <ul>
        <li>
          <NavLink to="/" exact>
            <img src={analytics} alt="analytics-icon" /> <span>Analytics</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/users">
            <img src={users} alt="analytics-icon" />
            <span>Users</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/customers">
            <img src={customers} alt="analytics-icon" />
            <span>Customers</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/orders">
            <img src={orders} alt="analytics-icon" />
            <span>Orders</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/carbon-management">
            <img src={carbon} alt="analytics-icon" />
            <span>Carbon Mgt</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/reports">
            <img src={reports} className="reports-icon" alt="reports-icon" />
            <span>Reports</span>
          </NavLink>
        </li>
        {verifyPermissions('integration', context.msalInstance)?(
        <li>
          <NavLink to="/xero">
            <img src={integrationIcon} alt="integration-icon" />
            <span>Integrate Xero</span>
          </NavLink>
        </li>):''}
      </ul>
    </div>
  );
};

export default SideNav;
