import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeOrderStatus,
  changeReadyToClaim,
  getTokenUrlList,
  sendQrCodesEmail,
  uploadTokenZip
} from '../../services/orderService';
import { Col, Form, Row, Button, Alert, Spinner } from 'react-bootstrap';
import { generateTokenFormSchema } from './formValidation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import Loader from '../../components/Loader';
import { verifyPermissions } from '../../common/permissionVerifier';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { QRCodeCanvas, QRCodeSVG } from '@cheprasov/qrcode';
import { imageTypes, tokenDeliveryMethods, tokenDeliveryTypes } from '../../util/constants';
// import { formatDate } from '../../common/formatter';
import { useDispatch } from 'react-redux';
import { clearNotification } from '../../app/actions';

const DeliverTokens = () => {
  const location = useLocation();
  const history = useHistory();
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const zip = require('jszip')();
  let reader = new FileReader();
  const [notification, setNotificationMessage] = useState(null);
  const [url, setUrl] = useState(null);
  const [readyToClaimApiRequestInProgress, setReadyToClaimApiRequestInProgress] = useState(false);
  const [sendQrCodesApiRequestInProgress, setSendQrCodesApiRequestInProgress] = useState(false);
  const [generateQRcodesInProgress, setGenerateQRcodesInProgress] = useState(false);
  const [sent, setSent] = useState(false);
  const [generated, setGenerated] = useState(false);

  const [qrImagePreview, setQrImagePreview] = useState(null);
  const [tokenDeliveryMethod, setTokenDeliveryMethod] = useState(null);
  const [tokenDeliveryType, setTokenDeliveryType] = useState(null);
  const [readyToClaim, setReadyToClaim] = useState(
    location.state && location.state.readyToClaim ? location.state.readyToClaim : false
  );

  const orderId = location.state && location.state.orderId ? location.state.orderId : null;
  const id = location.state && location.state.id ? location.state.id : null;
  // const receiverName = location.state && location.state.receiverName ? location.state.receiverName : null;
  // const batch = location.state && location.state.batch ? location.state.batch : null;
  const status = location.state && location.state.releaseStatus ? location.state.releaseStatus : null;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    defaultValues: {
      tokenDeliveryMethod: '',
      tokenDeliveryType: '',
      email: '',
      size: 100,
      imageType: ''
    },
    resolver: yupResolver(generateTokenFormSchema),
    mode: 'all'
  });

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        dispatch(clearNotification());
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const generateQRcodes = async (data) => {
    setGenerateQRcodesInProgress(true);
    try {
      const config = {
        size: data.size
      };
      const tokenResponse = await getTokenUrlList(orderId, instance);
      if (tokenResponse) {
        tokenResponse.forEach((token) => {
          if (data.imageType === 'JPEG' || data.imageType === 'PNG') {
            let image = new QRCodeCanvas(token, config).toDataUrl('image/' + data.imageType.toLowerCase());
            zip.file(`${token.split('/').pop()}.${data.imageType.toLowerCase()}`, image.split(';base64,').pop(), {
              base64: true
            });
            setQrImagePreview(image);
            setTokenDeliveryMethod(data.tokenDeliveryMethod);
          } else {
            let qrSVG = new QRCodeSVG(token, config);
            let svgImage = qrSVG.toDataUrl();
            zip.file(`${token.split('/').pop()}.${data.imageType.toLowerCase()}`, svgImage.split(';base64,').pop(), {
              base64: true
            });
            setQrImagePreview(svgImage);
            setTokenDeliveryMethod(data.tokenDeliveryMethod);
          }
        });

        const response = await zip.generateAsync({ type: 'blob' });
        let resp = '';
        if (response) {
          setGenerated(true);
          reader.readAsDataURL(response);
          reader.onload = async () => {
            let baseURL = reader.result;
            resp = await uploadTokens(baseURL);

            if (tokenDeliveryMethod === tokenDeliveryMethods.zipFile && resp) {
              setNotifications('success', 'QR codes are successfully generated. Please download the ZIP file');
              updateOrderStatus(resp);
            } else {
              setNotifications('success', 'QR codes are successfully generated');
            }
          };
        }
      }
    } catch (error) {
      console.log(error);
      if (error.status === 400 && error.detail.includes('already generated')) {
        setGenerated(true);
      }
      setNotifications('danger', error.title);
    } finally {
      setGenerateQRcodesInProgress(false);
    }
  };

  const uploadTokens = async (data) => {
    try {
      const response = await uploadTokenZip(data, instance);
      if (response) {
        setUrl(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendQRcodes = async (data) => {
    setSendQrCodesApiRequestInProgress(true);

    const tokenUrl = url ? url : null;

    const request = {
      id: orderId,
      tokenListURL: tokenUrl,
      tokenSendEmail: data.email
    };

    try {
      const response = await sendQrCodesEmail(orderId, request, instance);
      if (response) {
        setSent(true);
        updateOrderStatus();
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSendQrCodesApiRequestInProgress(false);
    }
  };

  const toggleReadyToClaim = async (readyToClaim, status) => {
    const value = {
      readyToClaim: readyToClaim,
      approvalStatus: null
    };
    setReadyToClaimApiRequestInProgress(true);
    try {
      const response = await changeReadyToClaim(orderId, value, instance);
      if (response) {
        setNotifications('success', readyToClaim ? 'Tokens are ready to claim' : 'Tokens cannot be claimed');
        setReadyToClaim(readyToClaim);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setReadyToClaimApiRequestInProgress(false);
    }
  };

  const updateOrderStatus = async (imgUrl) => {
    const tokenUrl = imgUrl ? imgUrl : url;
    const data = {
      approvalStatus: 5,
      tokenListURL: tokenUrl ? tokenUrl : ''
    };
    try {
      const response = await changeOrderStatus(location.pathname, dispatch, orderId, data, instance);
      if (response) {
        setUrl(response.tokenListURL ? response.tokenListURL : '');
        if (
          (tokenDeliveryMethod === tokenDeliveryMethods.email && url) ||
          tokenDeliveryType === tokenDeliveryTypes.urls
        ) {
          setNotifications('success', 'Tokens successfully sent', '/orders/view-order/' + id + '/' + orderId);
        }
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    }
  };

  const handleDeliveryTypeOnChangeEvent = (deliveryType) => {
    setTokenDeliveryType(deliveryType);
    reset();
    setValue('tokenDeliveryType', deliveryType);
  };

  return (
    <React.Fragment>
      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
      <div className="order-form-wrapper deliver-token-wrapper">
        <Form className="order-form" noValidate autoComplete="none">
          <Row>
            <Col lg={3}>
              <Form.Label>Token Delivery Type*</Form.Label>
              <Form.Control
                as="select"
                type="select"
                {...register('tokenDeliveryType')}
                isInvalid={errors?.tokenDeliveryType}
                onChange={(e) => handleDeliveryTypeOnChangeEvent(e.target.value)}
                disabled={!verifyPermissions('write', instance) || generated || generateQRcodesInProgress}
              >
                <option value="" disabled>
                  Please select Token Delivery Type
                </option>
                {Object.keys(tokenDeliveryTypes).map((deliveryType, index) => (
                  <option key={index} value={tokenDeliveryTypes[deliveryType]}>
                    {tokenDeliveryTypes[deliveryType]}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors?.tokenDeliveryType?.message}</Form.Control.Feedback>
            </Col>
            {tokenDeliveryType === tokenDeliveryTypes.qrCodes && (
              <>
                <Col lg={3}>
                  <Form.Label>Token Delivery Method*</Form.Label>
                  <Form.Control
                    as="select"
                    type="select"
                    {...register('tokenDeliveryMethod')}
                    isInvalid={errors?.tokenDeliveryMethod}
                    onChange={(e) => setTokenDeliveryMethod(e.target.value)}
                    disabled={!verifyPermissions('write', instance) || generated || generateQRcodesInProgress}
                  >
                    <option value="" disabled>
                      Please select Token Delivery Method
                    </option>
                    {Object.keys(tokenDeliveryMethods).map((deliveryMethod, index) => (
                      <option key={index} value={tokenDeliveryMethods[deliveryMethod]}>
                        {tokenDeliveryMethods[deliveryMethod]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors?.tokenDeliveryMethod?.message}</Form.Control.Feedback>
                </Col>
              </>
            )}
            {(tokenDeliveryType === tokenDeliveryTypes.urls || tokenDeliveryMethod === tokenDeliveryMethods.email) && (
              <>
                <Col lg={6}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Please enter Email"
                    {...register('email')}
                    isInvalid={errors?.email}
                    autoComplete="none"
                    maxLength={100}
                    disabled={!verifyPermissions('write', instance)}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
                </Col>
              </>
            )}
          </Row>
          {tokenDeliveryType === tokenDeliveryTypes.qrCodes && (
            <div className="token-generate">
              <Row>
                <Col lg={6}>
                  <Form.Label>Size</Form.Label>
                  <Form.Control
                    placeholder="Please enter Size"
                    {...register('size')}
                    isInvalid={errors?.size}
                    autoComplete="none"
                    maxLength={4}
                    type="number"
                    disabled={!verifyPermissions('write', instance)}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">{errors?.size?.message}</Form.Control.Feedback>
                </Col>

                <Col lg={6}>
                  <Form.Label>Select Image Format </Form.Label>
                  <div className="img-format-select">
                    {imageTypes.map((type, index) => (
                      <Form.Check
                        {...register('imageType')}
                        isInvalid={errors?.imageType}
                        inline
                        label={type}
                        type="radio"
                        value={type}
                      />
                    ))}
                  </div>
                  <Form.Control.Feedback type="invalid">{errors?.imageType?.message}</Form.Control.Feedback>
                </Col>
              </Row>
              <div className="token-preview">
                {qrImagePreview ? <img alt="tokenPreview" src={qrImagePreview} /> : ''}
              </div>
              {tokenDeliveryMethod === tokenDeliveryMethods.zipFile && url && (
                <a className="token-download" href={url}>
                  Download QR code zip file
                </a>
              )}
              <div className="form-buttons">
                <NavLink to="/Orders" className="btn btn-light">
                  Cancel
                </NavLink>
                <Button
                  type="button"
                  className="btn btn-primary m-2"
                  onClick={handleSubmit(generateQRcodes)}
                  disabled={
                    !verifyPermissions('write', instance) ||
                    generateQRcodesInProgress ||
                    sendQrCodesApiRequestInProgress ||
                    generated
                  }
                >
                  {generateQRcodesInProgress && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      variant="light"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  Generate QR Codes
                </Button>
              </div>
            </div>
          )}
          <div className="deliver-token-footer">
            <div className="ready-to-claim">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={() => toggleReadyToClaim(!readyToClaim, status)}
                  defaultChecked={readyToClaim}
                />
                <span className="slider round"></span>
              </label>
              <div className="text">
                <label>Tokens are ready to Claim</label>
              </div>
            </div>
            {(tokenDeliveryType === tokenDeliveryTypes.urls || tokenDeliveryMethod === tokenDeliveryMethods.email) && (
              <Button
                type="button"
                className="btn btn-primary m-2"
                onClick={handleSubmit(sendQRcodes)}
                disabled={
                  !verifyPermissions('write', instance) ||
                  generateQRcodesInProgress ||
                  readyToClaimApiRequestInProgress ||
                  sendQrCodesApiRequestInProgress ||
                  (tokenDeliveryType === tokenDeliveryTypes.qrCodes &&
                    tokenDeliveryMethod === tokenDeliveryMethods.email &&
                    !url) ||
                  sent
                }
              >
                {sendQrCodesApiRequestInProgress && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    variant="light"
                    style={{ marginRight: '10px' }}
                  />
                )}
                Send Tokens
              </Button>
            )}
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default DeliverTokens;
