import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import OrderSteps from './OrderSteps';
import BootstrapTable from 'react-bootstrap-table-next';
import { formatDate, formatTimeStamp } from '../../common/formatter';
import { useMsal } from '@azure/msal-react';
import {
  attachOrderCarbon,
  changeOrderStatus,
  changeReadyToClaim,
  detachOrderCarbon,
  getOrderReleaseHistoryById,
  sendForInvoice
} from '../../services/orderService';
import { approvalStatus, units, xeroInvoiceStatus } from '../../util/constants';
import TableNoData from '../../components/TableNoData';
import Loader from '../../components/Loader';
import ViewOrderModal from './ViewOrderModal';
import { verifyPermissions } from '../../common/permissionVerifier';
import ConfirmAction from '../../components/ConfirmAction';
import { NavLink } from 'react-router-dom';
import SendForInvoicing from './SendForInvoicing';
import Badge from 'react-bootstrap/Badge';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { appConfigs } from '../../util/appConfigs';

const columns = [
  {
    dataField: 'task',
    text: 'Task'
  },
  {
    dataField: 'user',
    text: 'User'
  },
  {
    dataField: 'date',
    headerClasses: 'custom-col',
    text: 'Date / Time',
    formatter: (cell, row) => formatTimeStamp(cell)
  }
];

const OrderDetails = (props) => {
  const { instance } = useMsal();
  const location = useLocation();
  const dispatch = useDispatch();
  const [creditApprovalStatus, setCreditApprovalStatus] = useState(false);
  const [orderApprovalStatus, setOrderApprovalStatus] = useState(false);
  const [tokenApprovalStatus, setTokenApprovalStatus] = useState(false);
  const [sentForRegistration, setSentForRegistration] = useState(false);
  const [deliveryApprovalStatus, setDeliveryApprovalStatus] = useState(false);
  const [approvalStatusApiRequestInProgres, setApprovalStatusApiRequestInProgres] = useState(false);
  const [revertStatusApiRequestInProgres, setRevertStatusApiRequestInProgres] = useState(false);
  const [orderReleaseHistory, setOrderReleaseHistory] = useState([]);
  const [releaseStatus, setReleaseStatus] = useState(props.orderRelease.approvalStatus);
  const [getOrdersRleaseHistoryApiRequestInProgress, setGetOrdersRleaseHistoryApiRequestInProgress] = useState(true);
  const [sendForInvoicingApiRequestInProgress, setSendForInvoicingApiRequestInProgress] = useState(false);
  const [carbonAttachementApiRequestInProgress, setCarbonAttachementApiRequestInProgress] = useState(false);
  const [carbonDetachementApiRequestInProgress, setCarbonDetachementApiRequestInProgress] = useState(false);
  const [confirmActionBool, setConfirmActionBool] = useState(false);
  const [sendForInvoicingModalKey, setSendForInvoicingModalKey] = useState(new Date().toISOString());
  const [confirmActionModalKey, setConfirmActionModalKey] = useState(new Date().toISOString() + 1);
  const [viewOrderModalModalKey, setViewOrderModalModalKey] = useState(new Date().toISOString() + 2);
  const [readyToClaim, setReadyToClaim] = useState(props.orderRelease.readyToClaim);
  const [viewOrder, setViewOrder] = useState(false);
  const [showSendForInvoicing, setShowSendForInvoicing] = useState(false);

  let xeroReferenceId =
    props.orderRelease.xeroInvoiceId !== '00000000-0000-0000-0000-000000000000'
      ? props.orderRelease.xeroInvoiceId
      : null;

  const approveStatus = async (releaseId, status) => {
    releaseStatus < status ? setApprovalStatusApiRequestInProgres(true) : setRevertStatusApiRequestInProgres(true);

    const data = {
      approvalStatus: status
    };

    try {
      const response = await changeOrderStatus(location.pathname, dispatch, releaseId, data, instance);
      if (response) {
        if (response.approvalStatus === 4 && response.unitId) {
          setReleaseStatus(status);
          setSentForRegistration(status === approvalStatus['Registration']);
          setViewOrder(false);
          props.setNotifications('success', `Order Release Successfully Sent For Registration`);
        } else {
          if (response.approvalStatus === 4) {
            props.setNotifications('danger', 'Something Went Wrong While Sending For Registration', null, viewOrder);
            setConfirmActionBool(false);
          } else {
            setReleaseStatus(status);
            setCreditApprovalStatus(status === approvalStatus['Credit']);
            setOrderApprovalStatus(status === approvalStatus['OrderDetails']);
            setTokenApprovalStatus(status === approvalStatus['TokenCreation']);
            setDeliveryApprovalStatus(status === approvalStatus['ReleaseTokens']);
          }
        }
      }
    } catch (error) {
      console.log(error);
      props.setNotifications('danger', error.title, null, viewOrder);
    } finally {
      releaseStatus < status ? setApprovalStatusApiRequestInProgres(false) : setRevertStatusApiRequestInProgres(false);
    }
  };

  const sendForInvoicing = async (value, reset) => {
    setSendForInvoicingApiRequestInProgress(true);
    try {
      const response = await sendForInvoice(location.pathname, dispatch, value, instance);
      if (response) {
        setShowSendForInvoicing(false);
        reset();
        props.selected(props.orderRelease.batch);
        props.sentForInvoicing(true);
        props.setNotifications('success', 'Order Sent For Invoicing Successfully');
      }
    } catch (error) {
      console.log(error);
      props.setNotifications('danger', error.title, null, showSendForInvoicing);
    } finally {
      setSendForInvoicingApiRequestInProgress(false);
    }
  };

  const attachCarbon = async (orderId) => {
    setCarbonAttachementApiRequestInProgress(true);
    try {
      const response = await attachOrderCarbon(location.pathname, dispatch, orderId, instance);
      if (response) {
        props.setNotifications('success', 'Carbon Attached To The Order Successfully');
        props.selected(props.orderRelease.batch);
        props.carbonAttachment(true);
      }
    } catch (error) {
      console.log(error);
      props.setNotifications('danger', error.title);
    } finally {
      setCarbonAttachementApiRequestInProgress(false);
    }
  };

  const detachCarbon = async (orderId) => {
    setCarbonDetachementApiRequestInProgress(true);
    try {
      const response = await detachOrderCarbon(location.pathname, dispatch, orderId, instance);
      if (response) {
        props.setNotifications('success', 'Carbon Detached From The Order Successfully');
        props.selected(props.orderRelease.batch);
        props.carbonAttachment(false);
      }
    } catch (error) {
      console.log(error);
      props.setNotifications('danger', error.title);
    } finally {
      setCarbonDetachementApiRequestInProgress(false);
    }
  };

  const getOrderHistory = async (id) => {
    setGetOrdersRleaseHistoryApiRequestInProgress(true);
    try {
      const response = await getOrderReleaseHistoryById(id, instance);
      if (response) {
        setOrderReleaseHistory(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetOrdersRleaseHistoryApiRequestInProgress(false);
    }
  };

  const toggleReadyToClaim = async (id, readyToClaim, status) => {
    const value = {
      readyToClaim: readyToClaim,
      approvalStatus: null
    };

    try {
      const response = await changeReadyToClaim(id, value, instance);
      if (response) {
        props.setNotifications(
          'success',
          readyToClaim ? 'Tokens Are Ready To Claim' : 'Tokens Cannot Claim',
          null,
          viewOrder
        );
        setReadyToClaim(readyToClaim);
      }
    } catch (error) {
      console.log(error);
      props.setNotifications('danger', error.title, null, viewOrder);
    }
  };

  const handleModalClose = (reset) => {
    setSendForInvoicingModalKey(new Date().toISOString());
    setShowSendForInvoicing(false);
    reset();
  };

  const handleConfirmActionModalClose = () => {
    setConfirmActionModalKey(new Date().toISOString());
    setConfirmActionBool(false);
  };

  const handleViewOrderModalClose = () => {
    setViewOrderModalModalKey(new Date().toISOString());
    setViewOrder(false);
  };

  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;

  useEffect(() => {
    if (props.orderRelease.id) {
      getOrderHistory(props.orderRelease.id);
    }
    props.setReadyToClaim(props.orderRelease.readyToClaim);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.orderRelease.id) {
      getOrderHistory(props.orderRelease.id);
    }
    //eslint-disable-next-line
  }, [releaseStatus]);

  return (
    <div className="order-details">
      <div className="order-details-header">
        <h3>Status</h3>
        <div className="button-section">
          {releaseStatus > 3 && props.orderRelease.pendingCarbon ? (
            <Button
              variant="primary"
              disabled={!verifyPermissions('approve', instance) || carbonAttachementApiRequestInProgress}
              onClick={() => attachCarbon(props.orderRelease.id)}
            >
              {carbonAttachementApiRequestInProgress && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  variant="light"
                  style={{ marginRight: '10px' }}
                />
              )}
              Attach Carbon
            </Button>
          ) : (
            releaseStatus > 3 && (
              <Button
                variant="warning"
                disabled={
                  !verifyPermissions('approve', instance) ||
                  carbonDetachementApiRequestInProgress ||
                  props.orderRelease.xeroInvoiceStatus === xeroInvoiceStatus[5]
                }
                onClick={() => detachCarbon(props.orderRelease.id)}
              >
                {carbonDetachementApiRequestInProgress && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    variant="light"
                    style={{ marginRight: '10px' }}
                  />
                )}
                Detach Carbon
              </Button>
            )
          )}
          <span> </span>
          <div>
            <Button
              variant="info"
              onClick={() => setShowSendForInvoicing(true)}
              disabled={props.orderRelease.xeroInvoiceStatus != null}
            >
              Send To Xero
            </Button>
            {props.orderRelease.xeroInvoiceStatus && (
              <Badge pill variant="warning" text="dark" className={props.orderRelease.xeroInvoiceStatus}>
                {props.orderRelease.xeroInvoiceStatus}
              </Badge>
            )}
            <span> </span>
            {props.orderRelease.xeroInvoiceStatus && xeroReferenceId && (
              <a href={appConfigs.xeroHost + `?InvoiceID=${xeroReferenceId}`} className="view-invoice">
                {' '}
                View Xero Invoice
              </a>
            )}
          </div>
        </div>
      </div>
      {props.apiRequestInProgress ? (
        <Loader />
      ) : (
        <OrderSteps
          data={[
            {
              completed: true,
              text: `Order Created on ${props.createdDate ? formatDate(props.createdDate) : ''}
              ${props.orderRelease.user?.trim() ? 'by ' + props.orderRelease.user : ''}`
            },
            { completed: releaseStatus >= 1 || creditApprovalStatus, text: 'Approve Credit' },
            { completed: releaseStatus >= 2 || orderApprovalStatus, text: 'Approve Order Details' },
            { completed: releaseStatus >= 3 || tokenApprovalStatus, text: 'Approve Token Creation' },
            { completed: releaseStatus >= 4 || sentForRegistration, text: 'Send For Registration' },
            { completed: releaseStatus >= 5 || deliveryApprovalStatus, text: 'Deliver Tokens' }
          ]}
        />
      )}
      <div className="order-details-header">
        <h3>Order details</h3>
        <div className="order-details-buttons">
          {releaseStatus > 0 && releaseStatus < 3 ? (
            <Button
              style={{ marginRight: '20px' }}
              variant="secondary"
              type="button"
              disabled={!verifyPermissions('approve', instance) || revertStatusApiRequestInProgres}
              onClick={() => {
                approveStatus(props.orderRelease.id, releaseStatus - 1);
              }}
            >
              {revertStatusApiRequestInProgres && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  variant="light"
                  style={{ marginRight: '10px' }}
                />
              )}
              Revert
            </Button>
          ) : (
            releaseStatus === 3 && (
              <Button
                style={{ marginRight: '20px' }}
                variant="outline-primary"
                type="button"
                disabled={revertStatusApiRequestInProgres}
                onClick={() => setViewOrder(true)}
              >
                {revertStatusApiRequestInProgres && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    variant="light"
                    style={{ marginRight: '10px' }}
                  />
                )}
                View Order
              </Button>
            )
          )}
          {releaseStatus < 4 ? (
            <Button
              variant="primary"
              type="button"
              disabled={!verifyPermissions('approve', instance) || approvalStatusApiRequestInProgres}
              onClick={() =>
                releaseStatus !== 3
                  ? approveStatus(props.orderRelease.id, releaseStatus + 1)
                  : setConfirmActionBool(true)
              }
            >
              {!viewOrder && !showSendForInvoicing && approvalStatusApiRequestInProgres && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  variant="light"
                  style={{ marginRight: '10px' }}
                />
              )}
              {releaseStatus < 1
                ? 'Approve Credit'
                : releaseStatus < 2
                ? 'Approve Order Details'
                : releaseStatus < 3
                ? 'Tokens Created'
                : releaseStatus < 4
                ? 'Send for Registration'
                : ''}
            </Button>
          ) : releaseStatus < 5 ? (
            <NavLink
              to={{
                pathname: '/orders/deliver-tokens',
                state: {
                  id: props.id,
                  orderId: props.orderRelease.id,
                  batch: props.orderRelease.batch,
                  receiverName: props.customer.name,
                  readyToClaim: readyToClaim,
                  releaseStatus: releaseStatus,
                  orderNo: props.orderNo
                }
              }}
              className="btn btn-primary"
            >
              {/* <img src={AddOrder} alt="add user icon" /> */}
              Deliver Tokens
            </NavLink>
          ) : (
            <div>
              <div className="order-detail-btns">
                {releaseStatus === 5 && props.orderRelease.tokenListURL && (
                  <a
                    href={props.orderRelease.tokenListURL}
                    className="download-tokens"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Tokens
                  </a>
                )}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip">
                      Tokens delivered successfully. Please click on 'Download Tokens' button to download the tokens.
                    </Tooltip>
                  }
                >
                  <span>
                    <Button variant="primary" type="button" disabled={releaseStatus === 5}>
                      Deliver Tokens
                    </Button>
                  </span>
                </OverlayTrigger>
              </div>
              <div className="ready-to-claim">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      toggleReadyToClaim(props.orderRelease.id, e.target.checked, releaseStatus);
                    }}
                    defaultChecked={props.orderRelease.readyToClaim}
                  />
                </label>
                <div className="text">
                  <label>Tokens Are Ready To Claim</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="order-details-items">
        <div className="item">
          <strong>Company</strong>
          <label>{props.customer.name}</label>
        </div>
        <div className="item">
          <strong>Brand</strong>
          <label>{props.product.brand ? props.product.brand : '-'}</label>
        </div>
        <div className="item">
          <strong>Product Name</strong>
          <label>{props.product.name ? props.product.name : '-'}</label>
        </div>
        <div className="item">
          <strong>Batch</strong>
          <label>{props.orderRelease.batch ? props.orderRelease.batch : '-'}</label>
        </div>
        <div className="item">
          <strong>Quantity</strong>
          <label>{props.orderRelease.tokenCount ? props.orderRelease.tokenCount : '-'}</label>
        </div>
        <div className="item">
          <strong>Token Size</strong>
          <label>
            {props.product.emissionPerItem && props.product.emissionPerItemUnit
              ? props.product.emissionPerItem + units[props.product.emissionPerItemUnit]
              : '-'}
          </label>
        </div>
        <div className="item carbon-emmission">
          <div className="units">
            <strong>
              Total Carbon Emission Units
              {props.orderRelease.pendingCarbon && (
                <Badge pill variant="warning" text="dark">
                  Pending
                </Badge>
              )}
            </strong>
            <label>
              {props.product.emissionPerItemUnit &&
                props.orderRelease.tokenCount * props.product.emissionPerItem +
                  units[props.product.emissionPerItemUnit]}
            </label>
          </div>
        </div>
      </div>

      {getOrdersRleaseHistoryApiRequestInProgress ? (
        <Loader />
      ) : (
        <div>
          <div className="order-details-header">
            <h3>Order Release History</h3>
          </div>
          <div className="order-tbl-responsive">
            <BootstrapTable
              classes="custom-tbl"
              keyField="id"
              data={orderReleaseHistory.map((item, index) => {
                item.id = index;
                return item;
              })}
              columns={columns}
              noDataIndication={() => <NoDataIndication />}
            />
          </div>
        </div>
      )}

      <ViewOrderModal
        key={viewOrderModalModalKey}
        show={viewOrder}
        handleClose={handleViewOrderModalClose}
        orderRelease={props.orderRelease}
        product={props.product}
        customer={props.customer}
        onSendForRegistration={() => {
          setConfirmActionBool(true);
        }}
        action="Send For Registration"
        toggleReadyToClaim={() => toggleReadyToClaim(props.orderRelease.id, !readyToClaim, releaseStatus)}
        readyToClaim={readyToClaim}
        notification={props.notification}
        instance={instance}
        isLoading={approvalStatusApiRequestInProgres}
      />

      <SendForInvoicing
        key={sendForInvoicingModalKey}
        title="Send To Xero"
        show={showSendForInvoicing}
        handleClose={handleModalClose}
        sendForInvoicing={sendForInvoicing}
        action="Send"
        notification={props.notification}
        isLoading={sendForInvoicingApiRequestInProgress}
        orderReleaseId={props.orderRelease.id}
        totalToken={props.orderRelease.tokenCount ? props.orderRelease.tokenCount : '-'}
        carbonUnits={props.orderRelease.tokenCount * props.product.emissionPerItem}
        emissionPerItem={props.product.emissionPerItem}
        unit={props.product.emissionPerItemUnit}
      />

      <ConfirmAction
        classes="confirm"
        key={confirmActionModalKey}
        show={confirmActionBool}
        title="Send for Registration"
        content="Are you sure you want to send this order for registration?"
        action="Confirm"
        onActionConfirm={() => {
          approveStatus(props.orderRelease.id, 4);
          setConfirmActionModalKey(new Date().toISOString());
          setConfirmActionBool(false);
        }}
        handleClose={handleConfirmActionModalClose}
      />
    </div>
  );
};

export default OrderDetails;
