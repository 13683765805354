import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumb } from 'react-bootstrap';
import AddOrder from '../../assets/images/icons/plus.svg';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { NavLink } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import TableNoData from '../../components/TableNoData';
import { getAllOrders } from '../../services/orderService';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { AppContext } from '../../app/App';
import { orderStatus, recordsPerPage } from '../../util/constants';
import Loader from '../../components/Loader';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoData from '../../components/NoData';
import { formatDate } from '../../common/formatter';
import SearchBar from '../../components/SearchBar';
import { Dropdown } from 'react-bootstrap';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import Dropdownmenu from '../../components/Dropdown';

const Orders = (props) => {
  const context = React.useContext(AppContext);

  const [allOrders, setAllOrders] = useState([]);
  const [getOrdersApiRequestInProgress, setGetOrdersApiRequestInProgress] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [notification, setNotificationMessage] = useState(null);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(false);

  const columns = [
    {
      dataField: 'orderNo',
      text: 'Order No',
      sort: true
    },
    {
      dataField: 'receiver.name',
      text: 'Company',
      sort: true
    },
    {
      dataField: 'createdOn',
      text: 'Date',
      sort: true,
      formatter: (cell, row) => formatDate(cell)
    },
    {
      dataField: 'tokenCount',
      text: 'Token Count',
      sort: true
    },
    {
      dataField: 'carbonUnits',
      text: 'Carbon Units',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Order Status',
      formatter: (cell) => orderStatus[cell],
      sort: true
    },
    {
      dataField: 'actions',
      headerClasses: 'custom-col',
      text: 'Action',
      formatter: (cell, row) => (
        <div className="dropdownmenu">
          <Dropdown>
            <Dropdown.Toggle variant="link" data-testid="toggle-btn">
              <BiDotsVerticalRounded />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdownmenu
                path={getOrderActions(row)[0].routeUrl}
                id={row.id}
                receiver={row.receiver ? row.receiver.name : ''}
                action={getOrderActions(row)[0].action}
                className={'view-order-link'}
              />
              <Dropdown.Divider />
              <Dropdownmenu
                path={getOrderActions(row)[1].routeUrl}
                id={row.id}
                receiver={'Re-Order'}
                action={getOrderActions(row)[1].action}
                className={'re-order-link'}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )
    }
  ];

  const options = {
    sizePerPage: recordsPerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    totalSize: totalCount,
    page: currentPage
  };

  const getOrderActions = (row) => {
    switch (row.satus) {
      case 1:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];

      case 2:
        let actions = [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];
        return actions;
      case 3:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];
      case 4:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];

      default:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];
    }
  };

  const getOrders = async (
    pageNumber = 1,
    searchKeyWord = null,
    sortBy = null,
    isAscSort = null,
    initialize = false
  ) => {
    setGetOrdersApiRequestInProgress(initialize);
    setTableLoading(true);

    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllOrders(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllOrders(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
          setTableLoading(false);
        }
      }
    } catch (error) {
      setNotifications('danger', error?.title);
      console.log(error);
    } finally {
      setTableLoading(false);
      setGetOrdersApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
      },
      type === 'success' ? 2000 : 5000
    );
  };

  useEffect(() => {
    getOrders(currentPage, null, null, null, true);
    // eslint-disable-next-line
  }, []);

  const onTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentPage(pageNumber);
    getOrders(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };
  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;
  return (
    <section>
      <ToolkitProvider keyField="id" data={allOrders} columns={columns} search={{ searchFormatted: true }}>
        {(props) => (
          <div>
            <div className="page-header">
              <div className="breadcrumb-wrapper">
                <Breadcrumb>
                  <Breadcrumb.Item>Orders</Breadcrumb.Item>
                </Breadcrumb>
                <SearchBar apiRequest={getOrders} className="table-search" />
              </div>
              <NavLink to="/orders/create-order" className="btn btn-primary">
                <img src={AddOrder} alt="add Order icon" />
                Create Order
              </NavLink>
            </div>
            {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
            {getOrdersApiRequestInProgress ? (
              <Loader />
            ) : !isTableInitiallyEmpty ? (
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="order-tbl-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      classes="custom-tbl"
                      remote
                      hover
                      loading={tableLoading}
                      pagination={totalCount >= recordsPerPage ? paginationFactory(options) : null}
                      onTableChange={onTableChange}
                      noDataIndication={() => <NoDataIndication />}
                      overlay={overlayFactory()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <NoData
                  link="/orders/create-order"
                  buttonText="Create New Order"
                  mainMessage="You do not have Orders right now!"
                  secondaryMessage="Please click 'Create New Order' to add a new Order"
                  icon={AddOrder}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </ToolkitProvider>
    </section>
  );
};

export default Orders;
