import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from 'react-bootstrap-table-next';
import TableNoData from '../../components/TableNoData';
import { getFilteredOrderReleases } from '../../services/orderService';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { AppContext } from '../../app/App';
import { recordsPerPage } from '../../util/constants';
import Loader from '../../components/Loader';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import SearchBar from '../../components/SearchBar';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

const OrderReleases = (props) => {
  const context = React.useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  const [allOrders, setAllOrders] = useState([]);
  const [getOrdersApiRequestInProgress, setGetOrdersApiRequestInProgress] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const approvalStatus =
    location.state && location.state.approvalStatus !== null ? location.state.approvalStatus : null;
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(false);

  const columns = [
    {
      dataField: 'orderNo',
      text: 'Order No',
      sort: true
    },
    {
      dataField: 'batch',
      text: 'Batch',
      sort: true,
      formatter: (cell, row) => {
        return cell ? cell : '-';
      }
    },
    {
      dataField: 'productName',
      text: 'Product',
      sort: true
    },
    {
      dataField: 'tokenCount',
      text: 'Token Count',
      sort: true
    },
    {
      dataField: 'carbonEmission',
      text: 'Carbon Emission',
      sort: true
    },
    {
      dataField: 'xeroInvoiceStatus',
      text: 'Invoice Status',
      formatter: (cell) => (cell ? cell : '-'),
      sort: true
    },
    {
      dataField: 'status',
      text: 'Release Status',
      formatter: (cell) => (cell ? cell : '-'),
      sort: true
    }
  ];

  const options = {
    sizePerPage: recordsPerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    totalSize: totalCount,
    page: currentPage
  };

  const getOrders = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setTableLoading(true);
    const filters = [
      {
        key: 'Status',
        value: String(approvalStatus)
      }
    ];
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort,
        filters
      };

      const response = await getFilteredOrderReleases(data, context.msalInstance);

      if (response && response.paginatedList) {
        setAllOrders(response.paginatedList);
        setTotalCount(response.totalCount);
        // if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          // setIsTableInitiallyEmpty(true);
        // } else {
          // setIsTableInitiallyEmpty(false);
        setTableLoading(false);
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetOrdersApiRequestInProgress(false);
    }
  };

  useEffect(() => {
    getOrders(currentPage);
    // eslint-disable-next-line
  }, []);

  const onTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentPage(pageNumber);
    getOrders(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push({
        pathname: `/orders/view-order/${row.orderId}/${row.id}`,
        state: { orderId: row.orderId, orderReleaseId: row.id, receiverName: row.company }
      });
    }
  };

  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;
  return (
    <section>
      <ToolkitProvider keyField="id" data={allOrders} columns={columns} search={{ searchFormatted: true }}>
        {(props) => (
          <div>
            <div className="page-header">
              <div className="breadcrumb-wrapper">
                <Breadcrumb>
                  <Breadcrumb.Item>Order Releases</Breadcrumb.Item>
                </Breadcrumb>
                {/* <SearchBar apiRequest={getOrders} className="table-search" /> */}
              </div>
            </div>
            {getOrdersApiRequestInProgress ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <BootstrapTable
                    {...props.baseProps}
                    remote
                    hover
                    loading={tableLoading}
                    pagination={totalCount >= recordsPerPage ? paginationFactory(options) : null}
                    onTableChange={onTableChange}
                    noDataIndication={() => <NoDataIndication />}
                    overlay={overlayFactory()}
                    rowEvents={rowEvents}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </ToolkitProvider>
    </section>
  );
};

export default OrderReleases;
