import React from 'react';
import nodataImg from '../assets/images/no-data.svg';

const TableNoData = (props) => {
  return (
    <div className="no-data">
      <img alt="No data currently" src={nodataImg} className="main-image" />
      <h1>{props.mainMessage}</h1>
      <h2>{props.secondaryMessage}</h2>
    </div>
  );
};

export default TableNoData;
