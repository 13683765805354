import { deleteReq, get, patch, post, put } from '../util/apiHelper';
import { addNotification } from '../app/actions';

export const getAllOrders = async (data, msalInstance) => {
  try {
    const response = await get(
      '/OrderManagement',
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFilteredOrderReleases = async (data, msalInstance) => {
  try {
    const response = await post(
      '/Analytics/ApprovalStatusList',
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {}),
        ...(data.filters.length > 0 ? { filters: data.filters } : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrder = async (orderId, msalInstance) => {
  try {
    const response = await get(`/OrderManagement/${orderId}`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const createOrder = async (requestData, msalInstance) => {
  try {
    const response = await post('/OrderManagement', requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const updateOrder = async (subjectId, requestData, msalInstance) => {
  try {
    const response = await put(`/OrderManagement/${subjectId}`, requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const deleteOrderRelease = async (subjectId, msalInstance) => {
  try {
    const response = await deleteReq(`/OrderManagement/DeleteOrderRelease?id=${subjectId}`, null, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const changeOrderStatus = async (page, dispatch, subjectId, data, msalInstance) => {
  try {
    const response = await patch(`/OrderManagement/PatchOrderRelease/${subjectId}`, data, true, msalInstance);
    if (response) {
      if (data.approvalStatus === 4) {
        dispatch(addNotification('success', page, `Order Release ${subjectId} successfully sent for registration`));
      }
      return response.data;
    }
    throw response;
  } catch (error) {
    dispatch(addNotification('danger', page, error.title));
    throw error;
  }
};

export const getTokenUrlList = async (subjectId, msalInstance) => {
  try {
    const response = await get(`/OrderManagement/GetTokenUrlList/${subjectId}`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const changeReadyToClaim = async (subjectId, value, msalInstance) => {
  try {
    const response = await patch(`/OrderManagement/PatchOrderRelease/${subjectId}`, value, true, msalInstance);
    if (response) {
      return response;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const sendQrCodesEmail = async (subjectId, value, msalInstance) => {
  try {
    const response = await post(`/OrderManagement/SendTokensEmail`, value, true, msalInstance);
    if (response) {
      return response;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const uploadTokenZip = async (data, msalInstance) => {
  try {
    const response = await post(`/OrderManagement/OrderUploadTokensFile`, { zipFileB64: data }, true, msalInstance);
    if (response) {
      return response;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const getOrderReleaseHistoryById = async (subjectId, msalInstance) => {
  try {
    const response = await get(`/OrderManagement/OrderReleaseHistory/${subjectId}`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const sendForInvoice = async (page, dispatch, value, msalInstance) => {
  try {
    const response = await post(`/OrderManagement/SendForInvoicing`, value, true, msalInstance);
    if (response) {
      dispatch(addNotification(true, page, 'Order release successfully sent for invoicing'));
      return response;
    }
    throw response;
  } catch (error) {
    dispatch(addNotification(false, page, error.title));
    throw error;
  }
};

export const attachOrderCarbon = async (page, dispatch, orderId, msalInstance) => {
  try {
    const response = await get(`/OrderManagement/OrderCarbonAttachment/${orderId}`, null, true, msalInstance);
    if (response) {
      dispatch(addNotification(true, page, `Carbon attached to the order ${orderId} successfully`));
      return response;
    }
    throw response;
  } catch (error) {
    dispatch(addNotification(false, page, error.title));
    throw error;
  }
};

export const detachOrderCarbon = async (page, dispatch, orderId, msalInstance) => {
  try {
    const response = await get(`/OrderManagement/OrderDeAttachCarbon/${orderId}`, null, true, msalInstance);
    if (response) {
      dispatch(addNotification(true, page, `Carbon detached from the order ${orderId} successfully`));
      return response;
    }
    throw response;
  } catch (error) {
    dispatch(addNotification(false, page, error.title));
    throw error;
  }
};
