import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthInteceptor } from '../components/authInterceptor';
import { RouteGuard } from '../components/RouterGuard';
import Analytics from '../features/analytics/Analytics';
import CarbonManagement from '../features/carbonMgt/CarbonManagement';
import CreateCustomer from '../features/customers/CreateCustomer';
import Customers from '../features/customers/Customers';
import EditCustomer from '../features/customers/EditCustomer';
import Orders from '../features/orders/Orders';
import ManageUser from '../features/users/ManageUser';
import Users from '../features/users/Users';
import ManageTradeAccount from '../features/carbonMgt/ManageTradeAccount';
import TradeAccountDetails from '../features/carbonMgt/TradeAccountDetails';
import TransferCarbon from '../features/carbonMgt/TransferCarbon';
import { appRoles } from '../util/authConfig';
import '../styles/style.scss';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ViewOrder from '../features/orders/ViewOrder';
import PageLayout from '../components/layouts/PageLayout';
import ManageOrder from '../features/orders/ManageOrder';
import XeroIntegration from '../features/orders/XeroIntegration';
import TransactionsUploader from '../features/carbonMgt/TransactionsUploader';
import Reports from '../features/reports/Reports';
import OrderReleases from '../features/orders/OrderReleases';
import "react-datepicker/dist/react-datepicker.css";

export const AppContext = React.createContext({ msalInstance: null });

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

const Pages = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Analytics />
      </Route>
      <RouteGuard
        exact
        path="/users"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={Users}
      />
      <RouteGuard
        exact
        path="/users/create-user"
        roles={[appRoles.adminUser, appRoles.c2zeroUser]}
        Component={ManageUser}
      />
      <RouteGuard
        exact
        path="/users/update-user"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={ManageUser}
      />
      <RouteGuard
        exact
        path="/customers"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={Customers}
      />
      <RouteGuard
        exact
        path="/customers/edit-customer"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={EditCustomer}
      />
      <RouteGuard
        exact
        path="/customers/create-customer"
        roles={[appRoles.adminUser, appRoles.c2zeroUser]}
        Component={CreateCustomer}
      />
      <RouteGuard
        exact
        path="/carbon-management"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser, appRoles.readOnlyUser]}
        Component={CarbonManagement}
      />
      <RouteGuard
        exact
        path="/carbon-management/create-trade-account"
        roles={[appRoles.adminUser, appRoles.c2zeroUser]}
        Component={ManageTradeAccount}
      />
      <RouteGuard
        exact
        path="/carbon-management/update-trade-account"
        roles={[appRoles.adminUser, appRoles.c2zeroUser]}
        Component={ManageTradeAccount}
      />
      <RouteGuard
        exact
        path="/carbon-management/trade-account-details"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser, appRoles.carbonMgtUser]}
        Component={TradeAccountDetails}
      />
      <RouteGuard
        exact
        path="/carbon-management/transfer-carbon"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser]}
        Component={TransferCarbon}
      />
      <RouteGuard
        exact
        path="/carbon-management/upload-transactions"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser]}
        Component={TransactionsUploader}
      />
      <RouteGuard
        exact
        path="/orders"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={Orders}
      />
      <RouteGuard
        exact
        path="/orders/create-order"
        roles={[appRoles.adminUser, appRoles.c2zeroUser]}
        Component={ManageOrder}
      />
      <RouteGuard
        exact
        path="/orders/edit-order"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={ManageOrder}
      />
      <RouteGuard
        exact
        path="/orders/re-order"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={ManageOrder}
      />
      <RouteGuard
        exact
        path="/orders/view-order"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={ViewOrder}
      />
      <RouteGuard
        exact
        path="/orders/view-order/:orderIdParam/:orderReleaseIdParam"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={ViewOrder}
      />
      <RouteGuard
        exact
        path="/orders/deliver-tokens"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={ManageOrder}
      />
      <RouteGuard
        exact
        path="/orders/order-releases"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={OrderReleases}
      />
      <RouteGuard
        exact
        path="/reports"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser, appRoles.readOnlyUser]}
        Component={Reports}
      />
      <RouteGuard
        exact
        path="/xero"
        roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}
        Component={XeroIntegration}
      />
    </Switch>
  );
};

const App = ({ msalInstance }) => {
  return (
    <Router>
      <AuthInteceptor>
        <AppContextProvider
          value={{
            msalInstance
          }}
        >
          <PageLayout>
            <Pages />
          </PageLayout>
        </AppContextProvider>
      </AuthInteceptor>
    </Router>
  );
};

export default App;
