export const userRoles = {
  adminUser: 'Admin',
  c2zeroUser: 'C2Zero User',
  readOnlyUser: 'Read Only User',
  carbonMgtUser: 'Carbon Management User'
};

export const userCategories = {
  0: 'None (not set)',
  1: 'Platform',
  2: 'Business Customer',
  3: 'Individual Customer'
};

export const userRolesArray = [
  { value: 'adminUser', label: 'Admin' },
  { value: 'c2zeroUser', label: 'C2Zero User' },
  { value: 'readOnlyUser', label: 'Read Only User' },
  { value: 'carbonMgtUser', label: 'Carbon Management User' }
];

export const partyTypes = {
  0: 'None',
  1: 'Platform',
  2: 'Customer',
  3: 'Individual'
};

export const reportTypes = {
  0: 'Scanned Details',
};

export const recordsPerPage = 10;

export const tradeAccountETSTypes = {
  0: 'Not set',
  1: 'EU ETS',
  2: 'NZ'
};

export const tradeAccountUsageTypes = {
  0: 'None (not set)',
  1: 'Warehouse (or holding)',
  2: 'Vault',
  3: 'External (not owned by C2Zero)',
  4: 'Redemption'
};

export const transactionTypes = {
  0: 'Not Set',
  1: 'Internal',
  2: 'Auction',
  3: 'Direct'
};

export const units = {
  0: 'NotSet',
  1: 'g',
  2: 'kg',
  3: 'mt'
};

export const orderStatus = {
  0: 'Not Set',
  1: 'Pending',
  2: 'Created',
  3: 'Partial Release',
  4: 'All Tokens Generated'
};

export const rolePermissions = {
  adminUser: ['read', 'write', 'approve', 'createAnyUser', 'integration'],
  c2zeroUser: ['read', 'write', 'createNonAdminUser'],
  readOnlyUser: ['read'],
  carbonMgtUser: ['manageCarbon']
};

export const approvalStatus = {
  NotSet: 0,
  Credit: 1,
  OrderDetails: 2,
  TokenCreation: 3,
  Registration: 4,
  ReleaseTokens: 5
};

export const approvalStatusArray = [
  'Not set',
  'Credits Approved',
  'Order Details Approved',
  'Token Creation Approved',
  'Sent for Registration',
  'Tokens Released'
];

export const pendingAprovalstatus = [
  'Pending Credit Approval',
  'Pending Order Details Approval',
  'Pending Token Creation Approval',
  'Pending Send For Registraion',
  'Pending Release Token',
];

export const imageTypes = ['PNG', 'SVG', 'JPEG'];

export const xeroInvoiceStatus = ['DRAFT', 'SUBMITTED', 'AUTHORISED', 'DELETED', 'VOIDED', 'PAID'];

export const tokenDeliveryTypes = {
  qrCodes: 'QR Codes',
  urls: 'Urls'
};

export const tokenDeliveryMethods = {
  email: 'Email',
  zipFile: 'Zip file'
};
