import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Modal, Button, Spinner, Form, Col, Row, Alert } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { verifyPermissions } from '../../common/permissionVerifier';
import * as yup from 'yup';
import { AppContext } from '../../app/App';
import InputGroup from 'react-bootstrap/InputGroup';
import { units } from '../../util/constants';
import { useState } from 'react';
import { currencyList } from '../../util/currencies';
import removeIcon from '../../assets/images/icons/remove.svg';

const SendForInvoicing = ({
  show,
  handleClose,
  sendForInvoicing,
  title,
  isLoading,
  actionButtonVariant,
  action,
  notification,
  orderReleaseId,
  totalToken,
  carbonUnits,
  emissionPerItem,
  unit
}) => {
  const context = React.useContext(AppContext);
  const [isTokenCountExceed, setIsTokenCountExceed] = useState(true);
  const initialLIneItems = {
    reference: null,
    currencyCode: null,
    lineItems: [
      {
        discount: 0,
        tokenCount: 0,
        unitPrice: 0
      }
    ]
  };

  const sendForInvoicingSchema = yup.object().shape({
    reference: yup
      .string()
      .required('Reference is a required field')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    currencyCode: yup
      .string()
      .required('Currency Code is a required field')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    lineItems: yup.array().of(
      yup.object().shape({
        unitPrice: yup
          .number()
          .required('Unit price is a required field')
          .nullable()
          .min(1, 'Unit Price should be greater than or equal 1')
          .transform((v, o) => (o === '' ? null : v)),
        discount: yup
          .number()
          .nullable()
          .min(0, 'Token Count should be greater than or equal 0')
          .transform((v, o) => (o === '' ? null : v)),
        tokenCount: yup
          .number()
          .required('Token Count is a required field')
          .nullable()
          .min(1, 'Token Count should be greater than or equal 1')
          .test('token-count-validation', 'Token Count Should Match The Total Token Count', function (value) {
            return validateTokenCount(value);
          })
      })
    )
  });

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: initialLIneItems,
    resolver: yupResolver(sendForInvoicingSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineItems'
  });

  const removeLineItem = (index) => {
    remove(index);
    if (index === 0){
      append({ tokenCount: 0, discount: 0, unitPrice: 0 });
    }
  };

  const validateTokenCount = () => {
    let totalCal = 0;
    let values = getValues();
    if (values.lineItems.length > 1) {
      values.lineItems.forEach((item) => {
        totalCal = totalCal + Number(item.tokenCount);
      });
      setIsTokenCountExceed(totalCal < totalToken);
      return totalCal === Number(totalToken);
    } else {
      setIsTokenCountExceed(values.lineItems[0].tokenCount < totalToken);
      return Number(values.lineItems[0].tokenCount) === totalToken;
    }
  };

  const onSendForInvoicing = (data) => {
    let newData = [];
    data.lineItems.forEach((item, index) => {
      newData[index] = { id: orderReleaseId, xeroReference: data.reference, CurrencyCode: data.currencyCode, ...item };
    });
    sendForInvoicing(newData, reset);
  };

  const closeModal = () => {
    handleClose(reset);
  };

  return (
    <Modal show={show} onHide={closeModal} size="lg" className="viewOrderModal">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="send-invoice">
        {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
        <Row>
          <Col md={4}>
            <p>
              Total Token Count: <b>{totalToken}</b>
            </p>
          </Col>
          <Col md={4}>
            <p>
              Emission Per Token: <b>{emissionPerItem + units[unit]}</b>
            </p>
          </Col>
          <Col md={4}>
            <p>
              Carbon Units: <b>{carbonUnits + units[unit]}</b>
            </p>
          </Col>
        </Row>
        <Row className="send-invoice-sec">
          <Col lg={3} md={6}>
            <Form.Label>Reference*</Form.Label>
            <div className="input-sec">
              <Form.Control
                placeholder="Please enter a reference"
                {...register('reference')}
                isInvalid={errors?.reference}
                autoComplete="none"
                type="text"
                disabled={!verifyPermissions('write', context.msalInstance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.reference?.message}
              </Form.Control.Feedback>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <Form.Label>Currency*</Form.Label>
            <div className="input-sec">
              <Form.Control
                as="select"
                type="select"
                {...register('currencyCode')}
                isInvalid={errors?.currencyCode}
                disabled={!verifyPermissions('write', context.msalInstance)}
              >
                <option value="">Please Select The Currency</option>
                {currencyList.map((currency, index) => (
                  <option key={index} value={currency.code}>
                    {currency.code}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors?.currencyCode?.message}</Form.Control.Feedback>
            </div>
          </Col>
        </Row>
        <Form className="order-form" noValidate autoComplete="none">
          {fields.map((item, index) => {
            return (
              <Row key={item.id} className="send-invoice-sec">
                <Col md={10}>
                  <Row>
                    <Col md={4} className="invoice-col">
                      <Form.Label>Token Count*</Form.Label>
                      <div className="input-sec">
                        <Form.Control
                          placeholder="Please enter Token Count"
                          {...register(`lineItems.${index}.tokenCount`)}
                          isInvalid={errors.lineItems?.[index]?.tokenCount}
                          autoComplete="none"
                          type="number"
                          disabled={!verifyPermissions('write', context.msalInstance)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <br />
                          {errors.lineItems?.[index]?.tokenCount?.message}
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                    <Col md={4} className="invoice-col">
                      <Form.Label>Unit Price*</Form.Label>
                      <div className="input-sec">
                        <Form.Control
                          placeholder="Please enter Unit Price"
                          {...register(`lineItems.${index}.unitPrice`)}
                          isInvalid={errors.lineItems?.[index]?.unitPrice}
                          autoComplete="none"
                          type="number"
                          disabled={!verifyPermissions('write', context.msalInstance)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <br />
                          {errors.lineItems?.[index]?.unitPrice?.message}
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                    <Col md={4} className="invoice-col">
                      <Form.Label>Discount*</Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Please enter Discount"
                          {...register(`lineItems.${index}.discount`)}
                          isInvalid={errors.lineItems?.[index]?.discount}
                          autoComplete="none"
                          type="number"
                          disabled={!verifyPermissions('write', context.msalInstance)}
                        ></Form.Control>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup>
                      <Form.Control.Feedback type="invalid" className="input-grp-feedback">
                        <br />
                        {errors.lineItems?.[index]?.discount?.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
                <Col md={2} className="invoice-col remove-button">
                  <div className="remove-btn">
                    <span className="close-btn" type="button" onClick={() => removeLineItem(index)}>
                      <img src={removeIcon} alt="remove-icon" />
                    </span>
                  </div>
                </Col>
              </Row>
            );
          })}

          <div className="add-release">
            <Button
              disabled={!isTokenCountExceed}
              variant="secondary"
              onClick={() => {
                append({ tokenCount: 0, discount: 0, unitPrice: 0 });
              }}
            >
              {' '}
              Add New Line
            </Button>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={actionButtonVariant}
          onClick={handleSubmit(onSendForInvoicing)}
          disabled={isLoading || !verifyPermissions('approve', context.msalInstance)}
        >
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              variant="light"
              style={{ marginRight: '10px' }}
            />
          )}
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendForInvoicing;
