import * as yup from 'yup';
import { tokenDeliveryMethods, tokenDeliveryTypes } from '../../util/constants';

yup.addMethod(yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    const len = list.length;
    for (let i = 0; i < len; i++) {
      let batch = list[i].batch;
      for (let j = i + 1; j < len; j++) {
        if (batch === list[j].batch) {
          return this.createError({
            path: `orderRelease.[${j}].batch`,
            message: 'Batch name should be unique'
          });
        }
      }
    }
    return true;
  });
});

export const orderFormSchema = yup.object().shape({
  receiverId: yup.string().required('Company / Individual Name is a required field'),
  vaultId: yup.string().required('Carbon Account is a required field'),
  brand: yup.string(),
  product: yup.object().shape({
    name: yup.string().required('Product Name is a required field'),
    emissionPerItem: yup
      .number()
      .min(1, 'Carbon Quantity per Token should be greater than or equal 1')
      .required('Carbon Quantity per Token is a required field')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    emissionPerItemUnit: yup.string().required('Unit is a required field'),
    text: yup.string().required('Product text is a required field').nullable()
  }),
  orderRelease: yup
    .array()
    .of(
      yup.object().shape({
        batch: yup.string().required('Batch is a required field'),
        tokenCount: yup
          .number()
          .min(1, 'Token Count should be greater than or equal 1')
          .required('Token Count is a required field')
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
      })
    )
    .unique('Batch must be unique')
});

export const generateTokenFormSchema = yup.object().shape({
  tokenDeliveryMethod: yup.string().when('tokenDeliveryType', {
    is: (val) => val === tokenDeliveryTypes.qrCodes,
    then: yup.string().required('Token delivery method is a required field')
  }),
  tokenDeliveryType: yup.string().required('Token delivery type is a required field'),
  email: yup.string().when(['tokenDeliveryMethod', 'tokenDeliveryType'], {
    is: (tokenDeliveryMethod, tokenDeliveryType) =>
      tokenDeliveryMethod === tokenDeliveryMethods.email || tokenDeliveryType === tokenDeliveryTypes.urls,
    then: yup.string().required('Email is a required field')
  }),
  size: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('tokenDeliveryType', {
      is: (val) => val === tokenDeliveryTypes.qrCodes,
      then: yup
        .number()
        .required('Size is a required field')
        .nullable()
        .min(100, 'QR code size should be greater than or equal 100px')
        .transform((v, o) => (o === '' ? null : v))
    }),
  imageType: yup.string().when('tokenDeliveryType', {
    is: (val) => val === tokenDeliveryTypes.qrCodes,
    then: yup.string().required('Image type is a required field')
  })
});
