import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import transferIcon from '../../assets/images/icons/repeat.svg';
import createIcon from '../../assets/images/icons/plus.svg';
import { NavLink } from 'react-router-dom';

import { getAllTradeAccounts } from '../../services/carbonManagementService';
import { AppContext } from '../../app/App';
import NoData from '../../components/NoData';
import Loader from '../../components/Loader';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { recordsPerPage } from '../../util/constants';
import overlayFactory from 'react-bootstrap-table2-overlay';
import TableNoData from '../../components/TableNoData';
import SearchBar from '../../components/SearchBar';
import { padLeadingZeros } from '../../common/formatter';

const columns = [
  {
    dataField: 'name',
    headerClasses: 'custom-col',
    text: 'Trade Account',
    sort: true
  },
  {
    dataField: 'accountNumber',
    headerClasses: 'custom-col2',
    text: 'Account No',
    sort: true
  },
  {
    dataField: 'totalCarbonUnits',
    headerClasses: 'custom-col',
    text: 'Available Carbon Units',
    formatter: (cell, row) =>
      cell
        ? Number(padLeadingZeros(cell)) !== 0
          ? padLeadingZeros(cell).toLowerCase().includes('tonnes')
            ? padLeadingZeros(cell)
            : padLeadingZeros(cell) + ' Tonnes'
          : 0 + ' Tonnes'
        : 0 + ' Tonnes'
  },
  {
    dataField: 'isActive',
    text: 'Status',
    formatter: (cell, row) => (cell ? 'Active' : 'Inactive')
  }
];

const CarbonManagement = () => {
  const context = React.useContext(AppContext);
  let history = useHistory();
  const [allTradeAccounts, setAllTradeAccounts] = useState([]);
  const [getTradeAccountsApiRequestInProgress, setGetTradeAccountsApiRequestInProgress] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(false);

  const options = {
    sizePerPage: recordsPerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    totalSize: totalCount,
    page: currentPage
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push({
        pathname: `/carbon-management/trade-account-details`,
        state: { tradeAccountId: row.id, tradeAccountName: row.name }
      });
    }
  };

  const getTradeAccounts = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    // setGetTradeAccountsApiRequestInProgress(true);
    setTableLoading(true);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };
      const response = await getAllTradeAccounts(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllTradeAccounts(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
          setTableLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetTradeAccountsApiRequestInProgress(false);
    }
  };
  useEffect(() => {
    getTradeAccounts();
    // eslint-disable-next-line
  }, []);
  const onTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentPage(pageNumber);
    getTradeAccounts(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };
  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;
  return (
    <section>
      <ToolkitProvider keyField="id" data={allTradeAccounts} columns={columns} search={{ searchFormatted: true }}>
        {(props) => (
          <div>
            <div className="page-header multiple-buttons">
              <div className="breadcrumb-wrapper">
                <Breadcrumb>
                  <Breadcrumb.Item>Carbon Management</Breadcrumb.Item>
                </Breadcrumb>
                <SearchBar apiRequest={getTradeAccounts} className="table-search" />
                <div className="button-wrapper2">
                  <div className="block-1">
                    <NavLink to="/carbon-management/create-trade-account" className="btn btn-outline-secondary">
                      <img src={createIcon} alt="add user icon" />
                      Create Trade Account
                    </NavLink>
                    <NavLink to="/carbon-management/upload-transactions" className="btn btn-outline-secondary">
                      Upload CSV
                    </NavLink>
                  </div>
                  <div className="block-2">
                    <NavLink to="/carbon-management/transfer-carbon" className="btn btn-primary">
                      <img src={transferIcon} alt="add user icon" />
                      Transfer Carbon
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {getTradeAccountsApiRequestInProgress ? (
              <Loader />
            ) : !isTableInitiallyEmpty ? (
              <div className="row">
                <div className="col-12">
                  <div className='carbon-tbl-responsive'>
                    <BootstrapTable
                      {...props.baseProps}
                      classes="custom-tbl"
                      remote
                      rowEvents={rowEvents}
                      hover
                      loading={tableLoading}
                      pagination={totalCount >= recordsPerPage ? paginationFactory(options) : null}
                      onTableChange={onTableChange}
                      noDataIndication={() => <NoDataIndication />}
                      overlay={overlayFactory()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <NoData
                  link="/carbon-management/create-trade-account"
                  buttonText="Create New Trade Account"
                  mainMessage="You do not have Trade Accounts right now!"
                  secondaryMessage="Please click 'Create New Trade Account' to add a new Trade Account"
                  icon={createIcon}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </ToolkitProvider>
    </section>
  );
};

export default CarbonManagement;
