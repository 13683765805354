import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import Unauthorized from './Unauthorized';

export const RouteGuard = ({ Component, ...props }) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const onLoad = async () => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount && currentAccount.idTokenClaims.extension_Role) {
      let intersection = props.roles.filter((role) => currentAccount.idTokenClaims.extension_Role.includes(role));

      if (intersection.length > 0) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    }
  };

  useEffect(() => {
    onLoad();
  });

  return (
    <>{isAuthorized ? <Route {...props} render={(routeProps) => <Component {...routeProps} />} /> : <Unauthorized />}</>
  );
};
