import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCustomer, getCustomer, getXeroContactsList, updateCustomer } from '../../services/partyService';
import { getPlatformUsers } from '../../services/userService';
import { Alert, Button, Form, Spinner, Col, Row } from 'react-bootstrap';
import { customerFormSchema, customerUpdateSchema } from './formValidation';
import { NavLink, useHistory } from 'react-router-dom';
import { FormSkeleton } from '../../components/formSkeleton';
import Select from 'react-select';
import { verifyPermissions } from '../../common/permissionVerifier';

const CustomerForm = ({ customerId }) => {
  const history = useHistory();
  const { instance } = useMsal();
  const [sameAsPrimary, setSameAsPrimary] = useState(false);
  const [notification, setNotificationMessage] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: '',
    partyType: '',
    postalAddressPostBox: '',
    postalAddressStreetName: '',
    postalAddressAdditionalStreetName: '',
    postalAddressBuildingName: '',
    postalAddressBuildingNumber: '',
    postalAddressCityName: '',
    postalAddressPostalZone: '',
    postalAddressCountry: '',
    primaryContactTitle: 'Mr',
    primaryContactName: '',
    billingContactEmail: '',
    primaryContactEmail: '',
    primaryContactTelephone: '',
    accountManagerId: '',
    relationshipManagerId: '',
    primaryContactForBilling: false,
    billingContactTitle: 'Mr',
    billingContactName: '',
    billingContactTelephone: '',
    billingContactNote: '',
    note: ''
  });

  const [getCustomerApiRequestInProgress, setGetCustomerApiRequestInProgress] = useState(true);
  const [getXeroContactsApiRequestInProgress, setGetXeroContactsApiRequestInProgress] = useState(false);
  const [saveCustomersApiRequestInProgress, setSaveCustomersApiRequestInProgress] = useState(false);
  const [getPlatformUsersApiRequestInProgress, setGetPlatformUsersApiRequestInProgress] = useState(false);
  const [platformUsers, setPlatformUsers] = useState([]);
  const [xeroReferenceContacts, setXeroReferenceContacts] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    onBlur,
    onTouched,
    getValues,
    control
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(customerId ? customerUpdateSchema : customerFormSchema),
    mode: 'all'
  });

  const resetForm = () => {
    reset();
    setSameAsPrimary(false);
  };

  const onSubmitHandler = (data) => {
    if (customerId) {
      customerUpdateSchema
        .validate(data)
        .then(function (value) {
          updateSingleCustomer(value);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      customerFormSchema
        .validate(data)
        .then(function (value) {
          setGetCustomerApiRequestInProgress(true);
          createNewCustomer(value);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  const createNewCustomer = async (customerData) => {
    setSaveCustomersApiRequestInProgress(true);
    try {
      const resp = await createCustomer(customerData, instance);
      if (resp) {
        await setNotifications('success', 'Customer Successfully Created', '/customers');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveCustomersApiRequestInProgress(false);
    }
  };

  const updateSingleCustomer = async (customerData) => {
    setSaveCustomersApiRequestInProgress(true);
    try {
      const resp = await updateCustomer(customerId, customerData, instance);
      if (resp) {
        await setNotifications('success', 'Customer Successfully Updated', '/customers');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveCustomersApiRequestInProgress(false);
    }
  };

  const getCustomerDetails = async (customerId) => {
    setGetCustomerApiRequestInProgress(true);
    try {
      const response = await getCustomer(customerId, instance);
      if (response) {
        setInitialValues(response);
        reset(response);
        setSameAsPrimary(response.primaryContactForBilling);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetCustomerApiRequestInProgress(false);
    }
  };

  const getXeroContacts = async () => {
    setGetXeroContactsApiRequestInProgress(true);
    try {
      const response = await getXeroContactsList(instance);
      if (response) {
        setXeroReferenceContacts(response.map((i) => ({ ...i, label: `${i.name} `, value: i.contactID })));
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetXeroContactsApiRequestInProgress(false);
    }
  };

  const fetchPlatformUsers = async () => {
    setGetPlatformUsersApiRequestInProgress(true);
    try {
      const response = await getPlatformUsers(instance);
      if (response) {
        setPlatformUsers(response.map((i) => ({ ...i, label: `${i.firstName} ${i.lastName}`, value: i.id })));
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetPlatformUsersApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  useEffect(() => {
    if (sameAsPrimary) {
      setValue('billingContactTitle', getValues('primaryContactTitle'));
      setValue('billingContactName', getValues('primaryContactName'));
      setValue('billingContactEmail', getValues('primaryContactEmail'));
      setValue('billingContactTelephone', getValues('primaryContactTelephone'));
    } else {
      setValue('billingContactTitle', 'Mr');
      setValue('billingContactName', '');
      setValue('billingContactEmail', '');
      setValue('billingContactTelephone', '');
    }
    // eslint-disable-next-line
  }, [sameAsPrimary]);

  useEffect(() => {
    fetchPlatformUsers();
    getXeroContacts();
    if (customerId) {
      getCustomerDetails(customerId);
    } else {
      setGetCustomerApiRequestInProgress(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
      <Form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="none">
        <Row>
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Company / Individual Name</Form.Label>
              <Form.Control
                placeholder="Please enter Company / Individual Name"
                {...register('name')}
                isInvalid={errors.name}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Division / Group</Form.Label>
              <Form.Control
                {...register('partyType')}
                isInvalid={errors.partyType}
                disabled={!verifyPermissions('write', instance)}
              >
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.partyType?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Xero Reference Contact</Form.Label>
              <Controller
                name="accountManagerId"
                control={control}
                {...register('xeroRefId')}
                render={({ field, fieldState: { invalid }, formState }) => {
                  return (
                    <React.Fragment>
                      <Select
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Please select Xero Reference Contact"
                        options={xeroReferenceContacts}
                        isInvalid={invalid}
                        onTouched={onTouched}
                        onBlur={onBlur}
                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                        defaultValue={xeroReferenceContacts.filter((contact) => contact.contactID === field.value)[0]}
                        // className={`form-control ${invalid ? 'is-invalid' : ''}`}
                        // styles={customStyles}
                        disabled={!verifyPermissions('write', instance)}
                        classNamePrefix="react-select"
                        isDisabled={!verifyPermissions('write', instance)}
                      />
                      {invalid && <div className="error">{errors.xeroRefId?.message}</div>}
                    </React.Fragment>
                  );
                }}
              />
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12}>
              <h6>
                <strong>Company Address</strong>
              </h6>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>Post Box</Form.Label>
              <Form.Control
                placeholder="Please enter post box"
                {...register('postalAddressPostBox')}
                isInvalid={errors.postalAddressPostBox}
                autoComplete="none"
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressPostBox?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>Building Number</Form.Label>
              <Form.Control
                placeholder="Please enter building number"
                {...register('postalAddressBuildingNumber')}
                isInvalid={errors.postalAddressBuildingNumber}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.postalAddressBuildingNumber?.message}
              </Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Building Name</Form.Label>
              <Form.Control
                placeholder="Please building name"
                {...register('postalAddressBuildingName')}
                isInvalid={errors.postalAddressBuildingName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressBuildingName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12}>
              <Form.Label>Street Name</Form.Label>
              <Form.Control
                placeholder="Please enter street name"
                {...register('postalAddressStreetName')}
                isInvalid={errors.postalAddressStreetName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressStreetName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12}>
              <Form.Label>Additional Street Name</Form.Label>
              <Form.Control
                placeholder="Please enter additional street name"
                {...register('postalAddressAdditionalStreetName')}
                isInvalid={errors.postalAddressAdditionalStreetName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.postalAddressAdditionalStreetName?.message}
              </Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="Please enter city"
                {...register('postalAddressCityName')}
                isInvalid={errors.postalAddressCityName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressCityName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Please enter state"
                {...register('postalAddressStateName')}
                isInvalid={errors.postalAddressStateName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressStateName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={2}>
              <Form.Label>Post Code</Form.Label>
              <Form.Control
                placeholder="Please enter post code"
                {...register('postalAddressPostalZone')}
                isInvalid={errors.postalAddressPostalZone}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressPostalZone?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={4}>
              <Form.Label>Country</Form.Label>
              <Form.Control
                placeholder="Please enter country"
                {...register('postalAddressCountry')}
                isInvalid={errors.postalAddressCountry}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressCountry?.message}</Form.Control.Feedback>
            </Col>
          )}
        </Row>
        <div className="inner-card">
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <div className="form-heading">
              <h3>Primary Contact</h3>
            </div>
          )}
          <Row>
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={2}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  type="select"
                  {...register('primaryContactTitle')}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactTitle', e.target.value, { shouldValidate: true })
                  }
                  disabled={!verifyPermissions('write', instance)}
                  isInvalid={errors.primaryContactTitle}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactTitle?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={10}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Please enter name"
                  {...register('primaryContactName')}
                  isInvalid={errors.primaryContactName}
                  autoComplete="none"
                  maxLength={100}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactName', e.target.value, { shouldValidate: true })
                  }
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactName?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Please enter email"
                  {...register('primaryContactEmail')}
                  isInvalid={errors.primaryContactEmail}
                  maxLength={100}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactEmail', e.target.value, { shouldValidate: true })
                  }
                  disabled={!verifyPermissions('write', instance)}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactEmail?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  placeholder="Please enter mobile number"
                  {...register('primaryContactTelephone')}
                  isInvalid={errors.primaryContactTelephone}
                  maxLength={100}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactTelephone', e.target.value, { shouldValidate: true })
                  }
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactTelephone?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>C2Zero Relationship Owner</Form.Label>
                <Controller
                  name="relationshipManagerId"
                  control={control}
                  {...register('relationshipManagerId')}
                  render={({ field, fieldState: { invalid }, formState }) => {
                    return (
                      <React.Fragment>
                        <Select
                          isSearchable={true}
                          isClearable={true}
                          placeholder="Please select C2Zero relationship owner"
                          options={platformUsers}
                          isInvalid={invalid}
                          onTouched={onTouched}
                          onBlur={onBlur}
                          onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                          defaultValue={platformUsers.filter((user) => user.id === field.value)[0]}
                          // className={`form-control ${invalid ? 'is-invalid' : ''}`}
                          // styles={customStyles}
                          classNamePrefix="react-select"
                          isDisabled={!verifyPermissions('write', instance)}
                        />
                        {invalid && <div className="error">{errors.relationshipManagerId?.message}</div>}
                      </React.Fragment>
                    );
                  }}
                />
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>C2Zero Account Manager</Form.Label>
                <Controller
                  name="accountManagerId"
                  control={control}
                  {...register('accountManagerId')}
                  render={({ field, fieldState: { invalid }, formState }) => {
                    return (
                      <React.Fragment>
                        <Select
                          isSearchable={true}
                          isClearable={true}
                          placeholder="Please select C2Zero Account Manager"
                          options={platformUsers}
                          isInvalid={invalid}
                          onTouched={onTouched}
                          onBlur={onBlur}
                          onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                          defaultValue={platformUsers.filter((user) => user.id === field.value)[0]}
                          // className={`form-control ${invalid ? 'is-invalid' : ''}`}
                          // styles={customStyles}
                          disabled={!verifyPermissions('write', instance)}
                          classNamePrefix="react-select"
                          isDisabled={!verifyPermissions('write', instance)}
                        />
                        {invalid && <div className="error">{errors.accountManagerId?.message}</div>}
                      </React.Fragment>
                    );
                  }}
                />
              </Col>
            )}
          </Row>
        </div>
        <div className="inner-card">
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <div className="form-heading">
              <h3>Billing Contact</h3>
              <Form.Check
                label="Same as Primary Contact"
                onClick={() => {
                  setSameAsPrimary(!sameAsPrimary);
                }}
                {...register('primaryContactForBilling')}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Check>
            </div>
          )}
          <Row>
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={2}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  type="select"
                  disabled={sameAsPrimary}
                  {...register('billingContactTitle')}
                  isInvalid={errors.billingContactTitle}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactTitle?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={10}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Please enter name"
                  {...register('billingContactName')}
                  disabled={sameAsPrimary || !verifyPermissions('write', instance)}
                  maxLength={100}
                  isInvalid={errors.billingContactName}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactName?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Please enter email"
                  {...register('billingContactEmail')}
                  disabled={sameAsPrimary || !verifyPermissions('write', instance)}
                  isInvalid={errors.billingContactEmail}
                  maxLength={100}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactEmail?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  placeholder="Please enter mobile number"
                  {...register('billingContactTelephone')}
                  disabled={sameAsPrimary || !verifyPermissions('write', instance)}
                  isInvalid={errors.billingContactTelephone}
                  maxLength={100}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactTelephone?.message}</Form.Control.Feedback>
              </Col>
            )}
          </Row>
        </div>
        <Row>
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12}>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows="4"
                {...register('note')}
                isInvalid={errors.note}
                maxLength={250}
                autoComplete="none"
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.note?.message}</Form.Control.Feedback>
            </Col>
          )}
        </Row>
        <div className="form-buttons">
          {customerId ? (
            <NavLink to="/customers" className="btn btn-light">
              Cancel
            </NavLink>
          ) : (
            <span onClick={resetForm} className="btn btn-light">
              Clear
            </span>
          )}
          <Button
            type="submit"
            className="btn btn-primary m-2"
            disabled={
              !verifyPermissions('write', instance) ||
              getCustomerApiRequestInProgress ||
              saveCustomersApiRequestInProgress ||
              getPlatformUsersApiRequestInProgress
            }
          >
            {saveCustomersApiRequestInProgress && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                variant="light"
                style={{ marginRight: '10px' }}
              />
            )}
            {customerId ? 'Update Customer' : 'Create Customer'}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CustomerForm;
