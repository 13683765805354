import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { getAllUsers } from '../../services/userService';
import { useHistory } from 'react-router';
import { Breadcrumb } from 'react-bootstrap';
import { AppContext } from '../../app/App';
import NoData from '../../components/NoData';
import addUserIcon from '../../assets/images/icons/user-plus.svg';
import Loader from '../../components/Loader';
import { NavLink } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { recordsPerPage } from '../../util/constants';
import overlayFactory from 'react-bootstrap-table2-overlay';
import TableNoData from '../../components/TableNoData';
import { formatRoles } from '../../common/formatter';
import SearchBar from '../../components/SearchBar';

const columns = [
  {
    dataField: 'firstName',
    text: 'Name',
    sort: true,
    formatter: (cell, row) => `${row.firstName || ''} ${row.lastName || ''}`
  },
  {
    dataField: 'roles',
    headerClasses: 'custom-col',
    text: 'USER TYPE',
    sort: true,
    formatter: (cell, row) => formatRoles(cell) || '-'
  }
];

const Users = () => {
  const context = React.useContext(AppContext);
  const [getUsersApiRequestInProgress, setGetUsersApiRequestInProgress] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  let history = useHistory();
  const [allUsers, setAllUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(false);

  const options = {
    sizePerPage: recordsPerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    totalSize: totalCount,
    page: currentPage
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push({
        pathname: '/users/update-user',
        state: { subjectId: row.subjectId, userName: `${row.firstName || ''} ${row.lastName || ''}` }
      });
    }
  };

  const getUsers = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    // setGetUsersApiRequestInProgress(true);
    setTableLoading(true);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllUsers(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllUsers(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetUsersApiRequestInProgress(false);
      setTableLoading(false);
    }
  };
  useEffect(() => {
    getUsers(currentPage);
    // eslint-disable-next-line
  }, []);

  const onTableChange = (type, { page, searchText, sortField, sortOrder }) => {
    const pageNumber = type === 'pagination' ? page : 1;
    setCurrentPage(pageNumber);
    getUsers(
      pageNumber,
      searchText,
      sortField ? sortField.toLowerCase() : null,
      sortOrder === 'asc' ? true : sortOrder === 'desc' ? false : null
    );
  };
  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;
  return (
    <ToolkitProvider keyField="id" data={allUsers} search={{ searchFormatted: true }} columns={columns}>
      {(props) => (
        <div>
          <div className="page-header">
            <div className="breadcrumb-wrapper">
              <Breadcrumb>
                <Breadcrumb.Item>Users</Breadcrumb.Item>
              </Breadcrumb>
              <SearchBar apiRequest={getUsers} className="table-search" />
            </div>
            <NavLink to="/users/create-user" className="btn btn-primary">
              <img src={addUserIcon} alt="add user icon" />
              Create User
            </NavLink>
          </div>
          {getUsersApiRequestInProgress ? (
            <Loader />
          ) : !isTableInitiallyEmpty ? (
            <div className="row">
              <div className="col-lg-12">
                <div className='user-tbl-responsive'>
                  <BootstrapTable
                    {...props.baseProps}
                    classes="custom-tbl"
                    remote
                    rowEvents={rowEvents}
                    hover
                    loading={tableLoading}
                    pagination={totalCount >= recordsPerPage ? paginationFactory(options) : null}
                    onTableChange={onTableChange}
                    noDataIndication={() => <NoDataIndication />}
                    overlay={overlayFactory()}
                  />
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <NoData
                link="/users/create-user"
                buttonText="Create New User"
                mainMessage="You do not have Users right now!"
                secondaryMessage="Please click 'Create New User' to add a new User"
                icon={addUserIcon}
              />
            </React.Fragment>
          )}
        </div>
      )}
    </ToolkitProvider>
  );
};

export default Users;
