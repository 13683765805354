import * as yup from 'yup';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export const customerFormSchema = yup.object().shape({
  name: yup.string().required('Company / Individual Name is a required field'),
  partyType: yup.string(),
  xeroRefId: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  postalAddressPostBox: yup.string(),
  postalAddressStreetName: yup.string().required('Street Name is a required field'),
  postalAddressCityName: yup.string().required('City Name is a required field'),
  primaryContactTitle: yup.string().required('Title is a required field'),
  primaryContactName: yup.string().required('Name is a required field'),
  primaryContactEmail: yup.string().required('Email is a required field').email('Invalid email format'),
  primaryContactTelephone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  billingContactTitle: yup.string().required('Title is a required field'),
  billingContactName: yup.string().required('Name is a required field'),
  billingContactEmail: yup.string().required('Email is a required field').email('Invalid email format'),
  billingContactTelephone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  accountManagerId: yup.string().required('C2Zero Account Manager is a required field'),
  relationshipManagerId: yup.string().required('C2Zero Relationship Owner is a required field')
});

export const customerUpdateSchema = yup.object().shape({
  name: yup.string().required('Company / Individual Name is a required field'),
  partyType: yup.string(),
  xeroRefId: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  postalAddressPostBox: yup.string(),
  postalAddressStreetName: yup.string().required('Street Name is a required field'),
  postalAddressCityName: yup.string().required('City Name is a required field'),
  primaryContactTitle: yup.string().required('Title is a required field'),
  primaryContactName: yup.string().required('Name is a required field'),
  primaryContactEmail: yup.string().required('Email is a required field').email('Invalid email format'),
  primaryContactTelephone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  billingContactTitle: yup.string().required('Title is a required field'),
  billingContactName: yup.string().required('Name is a required field'),
  billingContactEmail: yup.string().required('Email is a required field').email('Invalid email format'),
  billingContactTelephone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  accountManagerId: yup.string().required('C2Zero Account Manager is a required field'),
  relationshipManagerId: yup.string().required('C2Zero Relationship Owner is a required field')
});