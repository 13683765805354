export const appConfigs = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  tenant: process.env.REACT_APP_TENANT,
  clientId: process.env.REACT_APP_CLIENT_ID, 
  clientSecret: process.env.REACT_APP_CLIENT_SECRET, 
  tenantId: process.env.REACT_APP_TENANT_ID,
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN,
  tenantDomain: process.env.REACT_APP_TENANT_DOMAIN,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  authorityUrl: 'https://' + process.env.REACT_APP_AUTHORITY_DOMAIN + '/' + process.env.REACT_APP_TENANT_DOMAIN + '/',
  signInPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY,
  readScope: process.env.REACT_APP_READ_SCOPE,
  writeScope: process.env.REACT_APP_WRITE_SCOPE,
  loginUrl: process.env.REACT_APP_LOGIN_URL,
  apiHost: process.env.REACT_APP_API_HOST,
  azureStorageContainer: process.env.REACT_APP_AZURE_STORAGE_CONTAINER,
  xeroAuthEndpoint: process.env.REACT_APP_XERO_AUTHORIZE_ENDPOINT,
  xeroClientId: process.env.REACT_APP_XERO_CLIENT_ID,
  xeroRedirectUri: process.env.REACT_APP_XERO_REDIRECT_URI,
  xeroScopes: process.env.REACT_APP_XERO_SCOPES,
  xeroHost: process.env.REACT_APP_XERO_HOST
};
