import { get, post, put, patch } from '../util/apiHelper';

export const getAllCustomers = async (data, msalInstance) => {
  try {
    const response = await get(
      '/Party',
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const getCustomer = async (customerId, msalInstance) => {
  try {
    const response = await get(`/Party/${customerId}`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};
export const getXeroContactsList = async (msalInstance) => {
  try {
    const response = await get(`/Integration/GetContacts`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderHistory = async (customerId, data, msalInstance) => {
  try {
    const response = await get(
      `/Party/PartyOrders/${customerId}`,
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTokenData = async (customerId, data, msalInstance) => {
  try {
    const response = await get(
      `/Party/PartyOrderReleases/${customerId}`,
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const createCustomer = async (requestData, msalInstance) => {
  try {
    const response = await post('/Party', requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (customerId, requestData, msalInstance) => {
  try {
    const response = await put(`/Party/${customerId}`, requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const deactivateCustomer = async (data, requestData, msalInstance) => {
  try {
    const response = await patch(
      `/Party/DeActivateParty/${data.customerId}?onBoardStatus=${data.status}`,
      requestData,
      true,
      msalInstance
    );
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};
