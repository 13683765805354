import * as yup from 'yup';

export const tradeAccountFormSchema = yup.object().shape({
  etsType: yup
    .number()
    .nullable()
    .required('ETS Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  name: yup.string().required('Trade Account Name is a required field'),
  accountNumber: yup.string().required('Account Number is a required field'),
  usageType: yup
    .number()
    .nullable()
    .required('Usage Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  kyotoAccountNumber: yup.string()
});

export const tradeAccountUpdateSchema = yup.object().shape({
  etsType: yup
    .number()
    .nullable()
    .required('ETS Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  name: yup.string().required('Trade Account Name is a required field'),
  accountNumber: yup.string().required('Account Number is a required field'),
  usageType: yup
    .number()
    .nullable()
    .required('Usage Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  kyotoAccountNumber: yup.string()
});

export const transferFormSchema = yup.object().shape({
  fromId: yup.string().required('From is a required field'),
  toId: yup.string().required('To is a required field'),
  transactionId: yup.string().required('Transaction Id is a required field'),
  type: yup.string().required('Transaction Type is a required field'),
  transactionDate: yup.string().required('Transaction Date is a required field'),
  units: yup
    .number()
    .min(1)
    .nullable()
    .required('Units is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  serials: yup
    .array()
    .of(
      yup.object().shape({
        startSerial: yup
          .number()
          .nullable()
          .notRequired()
          .typeError('Start Serial must be a numeric')
          .positive('Start Serial must be a positive integer')
          .transform((value, originalValue) => (originalValue === '' ? null : value))
          .test(
            'start-corresponding-check', 
            'Start Serial is required when the End Serial is filled', 
            (value, context) => !value && context.parent.endSerial ? false : true
          ),
        endSerial: yup
          .number()
          .nullable()
          .notRequired()
          .typeError('End Serial must be a numeric')
          .positive('End Serial must be a positive integer')
          .transform((value, originalValue) => (originalValue === '' ? null : value))
          .test(
            'end-corresponding-check', 
            'End Serial is required when the Start Serial is filled', 
            (value, context) => !value && context.parent.startSerial ? false : true
          ),
    })
  )
});
